@import "color.scss";
@import "breakpoint.scss";
@import "mixin.scss";
@import "common.scss";

$spacing_limit: 46;
$spacing_includes: 50, 60, 70, 75, 80, 85, 100, 150, 200; // must greater than $spacing_limit

// This font is already Monoglyceride font. 
// Please do not add duplicate font with different name to avoid confusion. 
// If font has to be replaced, please replace the font file or other "monodb" named font-family will not be affected.
// @import url('https://fonts.cdnfonts.com/css/monoglyceride');
// @import "~bootstrap/scss/bootstrap";

html {
  overflow: hidden;
}

body {
  background: white;
  margin: 0;
  font-family: Kardust, KardustExpanded, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  //-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: $color;
  overflow: hidden;
}

// Remove preview image's background color
.lightbox .lb-image {
  border: none;
}

.icon-opacity {
  opacity: 0.2;
}

.anima-purple-circle {
  border: 2px solid #bf96ff;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.tag-bg {
  background: #F8A779;
}

.purple-color {
  color: #E0C6FF;
}

.purple-bg {
  background-color: #bf96ff;
}

.purple-circle {
  border: 1px solid #bf96ff;
  border-radius: 50%;
}

.purple-icon {
  fill: #bf96ff !important;

  g,
  path,
  circle {
    fill: #bf96ff !important;
    ;
  }
}

.filter-purple-icon {
  --color-1: #bf96ff;
  --color-2: #341D47;

  rect {
    fill: none;
    stroke: #bf96ff;
  }
}

.dark-purple-icon {
  --color-1: #341D47;
  --color-2: #bf96ff;

  rect {
    fill: #BF96FF;
    stroke: #291A3C;
  }
}

.white-icon {
  fill: #fff !important;

  g,
  path,
  circle {
    fill: #fff !important;
  }
}

.white-inactive-icon {
  stroke: #fff;

  ellipse {
    fill: #fff;
  }

  rect,
  path {
    stroke: #fff;
  }
}

.gray-icon {
  path {
    fill: #eee !important;
  }
}

.sky-blue-icon {
  fill: #00A8FF !important;

  path {
    fill: #00A8FF !important;
  }
}

.sky-blue-stroke-icon {
  stroke: #00A8FF !important;

  path {
    stroke: #00A8FF !important;
  }
}

.gradient-icon {
  fill: url(#gradient_paint) !important;

  path {
    fill: url(#gradient_paint) !important;
  }
}

.green-icon {
  fill: #22874A !important;

  path {
    fill: #22874A !important;
  }
}

.green-stroke-icon {
  stroke: #22874A !important;

  path {
    stroke: #22874A !important;
  }
}

.green-inactive-icon {
  stroke: #22874A;

  ellipse {
    fill: #22874A;
  }

  rect,
  path {
    stroke: #22874A;
  }
}

.w-450 {
  width: 450px !important;

  @include media-breakpoint-down('lg') {
    width: 100% !important;
  }
}

.square-avator {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.purple-border {
  border: 1px solid #bf96ff;
  border-radius: 5px;
  height: 28px;
  // min-width: 70px;
}

.title-style {
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0em;
}

.cookies-table-sp {
  // @include border-box(#BF96FF, 1px, 0);
  padding: 0 !important;
  border: 1px solid #BF96FF !important;

  td {
    // @include border-box(#BF96FF, 0.5px, 0);
    border: 1px solid #BF96FF !important;
    display: table-cell !important;
    min-width: 90px;
    line-height: 1.4em;
  }

  tr,
  td {
    border: none;
  }
}

table.table.table-bordered.text-white.cookies-table tr th {
  min-width: 200px;
}

table.table.table-bordered.text-white.cookies-table {
  font-size: 14px;
  border: 1px solid #BF96FF;
}

.custom-label {
  // font-family: 'Kardust' !important;
  font-size: 20px;

  span {
    font-family: 'Kardust' !important;
    font-size: 20px;
  }
}

#termofuse,
#privacy {
  font-family: 'Hiragino Sans' !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 33.9px;
  text-align: center;

  @include media-breakpoint-down('sm') {
    font-size: 16px !important;
  }

  @include media-breakpoint-down('xsm') {
    font-size: 16px !important;
  }
}

#term-title,
#privacy-title {
  font-family: 'Hiragino Sans' !important;
  font-size: 30px;
  font-weight: 400;
  line-height: 33.9px;
  text-align: center;
  margin-bottom: 30px;
}

table.table.table-bordered.text-white.cookies-table tr th {
  min-width: 200px;
}

table.table.table-bordered.text-white.cookies-table {
  font-size: 14px;

  td {
    line-height: 1.4em;
  }
}

.account-name {
  font-family: 'Kardust Expanded' !important;
  font-size: 25px !important;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left !important;
}

.account-email-label,
.account-mobile-label,
.account-mfa-label,
.account-password-label {
  font-family: 'Kardust' !important;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left !important;
}

.account-email,
.account-phone {
  font-family: 'Kardust' !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left !important;
}

span.wallet-support-font {
  font-family: 'Kardust Expanded' !important;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 169.5%;
}

.wallet-support-mail {
  font-family: 'Kardust Condensed' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 25px !important;
  line-height: 169.5%;
  color: #fff;
}

.wallet-address-title {
  font-family: 'Kardust Expanded' !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.my-wallet-address {
  font-family: 'Kardust Expanded' !important;
  font-style: normal;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px;
  letter-spacing: 0em;
}

@media only screen and (max-width: 425px) {

  /*Small smartphones [325px -> 425px]*/
  .filter-token {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {

  /*Tablets [601px -> 1200px]*/
  .filter-token {
    display: none;
  }
}

button.btn.btn-custom-cancel.btn-mini {
  font-size: 25px;
}

.common-btn {
  max-width: 330px !important;
  width: 100% !important;
  height: 50px !important;
  line-height: 15px !important;
  margin: 0 auto;

  @include media-breakpoint-up('lg') {
    width: 330px !important;
  }
}

.wallet-title {
  font-family: 'Kardust' !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.total-count {
  font-family: 'Kardust' !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.send-anima-title {
  font-family: 'Kardust';
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.manaAddress {
  font-size: 25px !important;
}

#mana-address-btn {
  font-family: 'Monoglyceride' !important;
  font-size: 25px !important;
}

.warning-label {
  font-family: 'Kardust Condensed' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 169.5%;
  text-align: justify;
  color: #FFFFFF;
}

// @media (min-width: 992px) {
//   .col-lg-2 {
//     flex: 1 0 auto;
//     width: 16.66666667%;
//   }
// }

@media (min-width: 1920px) and (max-width: 2560px) {
  .wp-lg-15 {
    width: 15%;
  }

  .wp-lg-20 {
    width: 25%;
  }

  .wp-lg-30 {
    width: 30%;
  }

  li.react-multi-carousel-item.react-multi-carousel-item--active.multi-carousel-item.mb-0,
  li.react-multi-carousel-item.multi-carousel-item.mb-0 {
    width: 250px !important;
  }

  .col-res-xl-20 {
    width: 16% !important;
    float: left;
    margin-left: 10px;
  }
}

.octillionLogo:hover {
  border-color: white;
}

.octillionLogo {
  border: 1px solid transparent;
  transition: border-color 1s;
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
}

@include media-breakpoint-up('md') {
  .wp-lg-15 {
    width: 15% !important;
  }

  .wp-lg-20 {
    width: 30%;
  }

  .wp-lg-30 {
    width: 30% !important;
  }
}

// .card.item-card {
//   width: 280px;
// }

span.kardust-light-font.term-policy,
a.kardust-light-font.term-policy {
  font-family: 'Hiragino Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 169.5%;
}

span.important-point {
  font-size: 15px !important;
  font-family: 'Kardust' !important;
  font-style: normal;
  font-weight: 400;
}

.changePassword-label {
  font-family: 'Kardust' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px !important;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

input.auth-form-input-absolute.p-2.px-3.w-100.changePassword-pleaceholder::placeholder {
  font-family: 'Kardust Expanded' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 23px;
}


@media(max-height: 740px) {
  .row.menulist {
    height: 170px !important;
  }
}

// @media (max-width: 576px) {
//   .menu-card>.card {
//     height: 70px !important;
//   }
// }

.version-code {
  font-family: 'Kardust' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 26px;
}

.form-select {
  height: 45px !important;
  padding-top: 5px;
}

.dropdown-icon {
  top: 15px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color;
}

.label-placeholder {
  color: $placeholder-color;
  opacity: 0.7;
}

.iframe-wrapper {
  // background: transparent;
}


#anicana-portal {
  min-height: 100%;
  background: $gradient-background;
}

.gradient-bg {
  // background: #19162d;
  background: linear-gradient(192.05deg, #4B225C 23.44%, #19162D 50%, #19162D 100%);
}

.drawchain-bg {
  background: #19162d;
}

.app-wrapper,
.levias-app-wrapper,
.swal-react-modal {
  min-height: 100vh;
  overflow: inherit;
  mix-blend-mode: normal;

  @include media-breakpoint-up('lg') {
    height: 100vh;
    overflow: hidden;
  }
}

.scrollable-app-wrapper {
  height: 100vh !important;
  overflow: auto !important;
}

body,
.app-wrapper,
.levias-app-wrapper,
.swal-react-modal {
  .body-overlay {
    z-index: 0;
    opacity: 0.1;
    pointer-events: none;
  }

  .mainbody {
    padding: 0px 25px 80px;
    height: $heightWithoutNav;
    height: calc($heightWithoutNav2 + 24px) !important;
    overflow: auto;

    &.hasFixedBottom {
      &:not(.sidebarBrandFixed) {
        height: calc($heightWithoutNav2 - 30px) !important;
      }

      &.sidebarBrandFixed {
        height: calc(100dvh - 67px - 100px) !important;
        margin-top: 80px;

        .sidebar-brand {
          width: 100%;
          padding: 0 25px !important;
          position: fixed;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .offOverFlowX {
    overflow-x: hidden !important;
  }

  .positionFixed {
    position: fixed;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholder-color;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder-color;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $placeholder-color;
  }

  .italic {
    font-style: italic;
  }

  .bg-2 {
    background-color: $bg-color-2 !important;
  }

  .bg-transparent {
    background: transparent !important;
  }

  option {
    font-size: 20px;
  }

  .logo {
    max-width: 150px;

    @include media-breakpoint-down("lg") {
      max-width: 100px;
    }
  }

  .icon {
    fill: $color;

    &.active {
      stroke: $gradient-color;
      fill: url(#gradient_paint) !important;
    }

    &.active-2 {
      stroke: $gradient-color;
      fill: url(#gradient_paint_2) !important;
    }

    &.active-3 {
      fill: $purple !important;
    }

    &.disabled {
      fill: $default-color;
    }

    &.large {
      width: 82px !important;
      height: 26px !important;
    }
  }

  .icon-stroke {
    stroke: $color;

    &.active {
      stroke: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
      // filter: url(#filter_paint);
      stroke: url(#gradient_paint);
    }

    &.active-2 {
      stroke: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
      stroke: url(#gradient_paint_2);
    }

    &.active-3 {
      stroke: $purple;
    }
  }

  .title {
    font-weight: bold;
    cursor: pointer;
  }

  .overflow-scroll-x {
    overflow-x: scroll;
  }

  .overflow-hidden-x {
    overflow-x: hidden !important;
  }

  .overflow-scroll-y {
    overflow-y: scroll;
  }

  .overflow-hidden-y {
    overflow-y: hidden !important;
  }

  .overflow-clip {
    overflow: hidden !important;
    overflow: clip !important;
  }

  .badge-custom {
    width: 24px;
    height: 24px;
    background: $badge-bg-color;
    color: $color;
    font-weight: 600;
    font-size: 12px;
    border-radius: 100px;
    @include flex-center;
    @extend .f-expended;
  }

  .badge-custom-2 {
    @extend .badge-custom;
    background: $purple;
    -webkit-text-fill-color: $bg-trasparent-color;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    padding-bottom: 2px;
  }

  .badge-custom-3 {
    @include flex-center;
    font-family: 'Kardust';
    font-size: 13px;
    font-weight: 400;
    background: $tag-color;
    color: $bg-trasparent-color;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 1px;
    border-radius: 2px;
    line-height: 16.64px;
    height: 15px;
  }

  .badge-wrapper {
    i.icon {
      position: absolute;
    }

    .badge-custom {
      @include flex-center;
      background-color: $badge-color !important;
      border-radius: 50px;
      width: 15px;
      height: 15px;
      font-size: 8px;
      position: relative;
      top: -9px;
      left: 9px;
    }
  }

  .flex-center {
    @include flex-center;
  }

  .flex-center-left {
    display: flex !important;
    justify-content: left;
    align-items: center;
  }

  .thumbnail {
    @include thumbnail;
  }

  .thumbnail {
    &-lg {
      @include thumbnail("lg");

      &-fixed {
        @include thumbnail("lg", fixed);
      }
    }

    &-md {
      @include thumbnail("md");

      &-fixed {
        @include thumbnail("md", fixed);
      }
    }

    &-sm {
      @include thumbnail("sm");

      &-fixed {
        @include thumbnail("sm", fixed);
      }
    }
  }

  .recommended-item {
    img {
      width: 100%;
      max-width: 185px;
      height: 105px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
    }

    .name {
      font-family: 'Kardust';
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
    }

    .price,
    .time {
      font-family: 'Kardust';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */

      text-align: justify;
      letter-spacing: 0.03em;

      color: $color-dim;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .divider-bg {
    background: linear-gradient(192.05deg, #391e4c 0%, #19162d 50%, #19162d 100%);
    z-index: 1000;
    padding: 0 0 1px 0;
  }

  .home-divider {
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg,
        rgba(232, 94, 255, 0) 0%,
        #bf96ff 31.77%,
        #bf96ff 66.15%,
        rgba(183, 121, 248, 0) 100%);
    margin: 0px 30px;
    z-index: 1000;
  }

  .divider {
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(270deg,
        rgba(232, 94, 255, 0) 0%,
        #e85eff 31.77%,
        #b779f8 66.15%,
        rgba(183, 121, 248, 0) 100%);
    margin: 0px 30px;
    background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 100%);
    z-index: 1000;
  }

  .divider-vertical {
    max-width: 1px;
    height: 225px;
    background-image: url(../../assets/images/border-vertical.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .divider-dashed {
    border-bottom: 1px dashed;
    border-color: $border-color;
  }

  .divider-primary {
    border-bottom: 1px solid;
    border-color: #495376;
  }

  .divider-fixed {
    width: calc(95% - $sidebarWidth);
    position: fixed;
    // top: calc($navHeight + 5px);
    // margin: 0;
    border-width: 0.5px;
    z-index: 2;
  }

  .alert-default {
    border: 1px solid $color;
  }

  .form-check-label {
    cursor: pointer;
  }

  .f-regular {
    font-family: "Kardust" !important;
  }

  .f-expended {
    font-family: "KardustExpanded" !important;
  }

  .f-expended-regular {
    font-family: "KardustExpandedRegular" !important;
  }

  .f-condensed {
    font-family: "KardustCondensed" !important;
  }

  .f-condensed-light {
    font-family: "KardustCondensedLight" !important;
  }

  .f-monodb {
    font-family: "Monoglyceride" !important;
  }

  .f-notosan {
    font-family: 'Noto Sans JP', 'Noto Sans', sans-serif !important;
  }

  .f-cambay {
    font-family: "Cambay" !important;
  }

  .child-fs-16 {
    * {
      font-size: 16px !important;
    }
  }

  .fs-20 {
    font-size: 20px !important;

    .ladda-label {
      font-size: 20px !important;
    }
  }

  .fs-25 {
    font-size: 25px !important;

    .ladda-label {
      font-size: 25px !important;
    }
  }

  .fw-400 {
    font-weight: 400 !important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  .fw-700 {
    font-weight: 700 !important;
  }

  .fw-bold {
    font-weight: bold !important;
  }

  .fw-normal {
    font-weight: normal !important;
  }

  .ml--10 {
    margin-left: -10px;
  }

  .disabled {
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    opacity: .5;
  }

  a,
  a:hover {
    cursor: pointer;
    color: $color;
  }

  a.disabledBtn {
    pointer-events: none;
    cursor: default;
  }

  .text-disable {
    color: $disable-color;
  }

  .search-input-group,
  .search-input-group-2,
  .search-input-group-3 {
    width: 500px !important;
    max-width: 100%;
    box-shadow: 1px 1px 10px #00000010;

    @include media-breakpoint-down("sm") {
      width: 100% !important;
      margin-top: 15px;
    }

    &.border-input {
      box-shadow: none;

      input {
        border: 1px solid rgba($color, 0.5) !important;
        border-radius: 10px !important;
      }
    }

    .search-icon,
    .search-icon-2,
    .search-icon-3 {
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 3;
    }

    .search-icon-2 {
      top: 8px;
    }

    .search-input,
    .search-input-2,
    .search-input-3 {
      border: none;
      background-color: $bg-color-2;
      padding-left: 48px;
      padding-top: 4px;
      padding-bottom: 8px;
      // width: 1% !important;

      @include media-breakpoint-down("sm") {
        width: 1% !important;
      }
    }

    .search-input-2 {
      background-color: $bg-color;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }

    .search-input-3 {
      background-color: $bg-color-2;
      height: 50px;
      border-radius: 0;
      padding-right: 48px;
    }

    .input-group-text {
      border: none;
      height: 50px;
      background-color: $bg-color-2;
    }
  }

  .search-input-group-2 {
    width: 300px !important;
  }

  .search-input-group-3 {
    width: 100% !important;
    margin-top: 0px !important;

    .btn-close {
      top: 13px;
      stroke: $color !important;
      // opacity: 0.5 !important;
      z-index: 3;
    }
  }

  .sidebar-flex {
    @include sidebar-flex;
  }

  select {
    appearance: none;
  }

  select.sort {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $color;
    background-image: url(../../assets/icons/sort_white.svg);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    color: #fff;
  }

  .navbar {
    box-shadow: 0px 4px 4px rgba($color, 0.25);
    background-color: $bg-color-2;
    margin-bottom: 0px;
  }

  small {
    font-size: 14px;
  }

  p {
    font-size: 18px;
  }

  span {
    font-size: 20px;

    &.more {
      @include media-breakpoint-down("sm") {
        font-size: 16px;
      }
    }
  }

  svg.more {
    @include media-breakpoint-down("sm") {
      width: 16px !important;
      ;
    }
  }

  h3,
  .header {
    font-size: 30px;
  }

  .subHeader {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #bf96ff;
    // background: $gradient-color;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // text-fill-color: transparent;

    @media (min-width: 767px) {
      .pc-mb-1 {
        margin-bottom: 1rem;
      }
    }
  }

  .products-title {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 380px) {
    .products-title {
      flex-direction: column;

      div {
        &:nth-of-type(2) {
          margin-top: 10px;
        }
      }
    }
  }

  .btn-small {
    min-width: 185px;
  }

  .btn-fit {
    width: fit-content !important;
  }

  .btn-close {
    position: absolute;
    top: 28px;
    right: 15px;
    text-align: right;
    fill: #fff !important;
    stroke: #fff !important;
    opacity: 1;

    @include media-breakpoint-up("lg") {
      display: none;
    }
  }

  .btn-bold {
    font-weight: bold;
  }

  .btn-150 {
    width: 150px;
  }

  .btn-custom,
  .btn-custom:hover {
    @include btn-custom;
    color: $color;
  }

  .btn-custom-2,
  .btn-custom-2:hover {
    @include btn-custom($btn-color-2);
    color: #fff !important;
  }

  .btn-custom-3,
  .btn-custom- .btn-custom-3:hover {
    @include btn-custom($btn-color-3);
    color: #fff !important;
  }

  .btn-custom-4,
  .btn-custom-4:hover {
    @include btn-custom($btn-color-4);
  }

  .btn-custom-5,
  .btn-custom-5:hover {
    @include btn-custom(transparent, transparent, 0px);
    background: linear-gradient(268.5deg, #ab79f8 0%, #5f49b5 99.48%);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    color: $color;
    border: none;
    @include flex-center;
  }

  .make-bid,
  .make-bid:hover {
    background: linear-gradient(268.5deg, #E98D3D 0%, #E98D3D 99.48%) !important;
  }

  .btn-sell,
  .btn-sell:hover {
    background: #5AA06E !important;
  }

  .btn-sell,
  .btn-send {
    font-family: 'Monoglyceride';
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }

  #detail-label {
    font-family: 'Monoglyceride' !important;
    font-size: 35px;
    font-weight: 400 !important;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left !important;
  }

  .btn-custom-6,
  .btn-custom-6:hover {
    @include btn-custom($color);
  }

  .btn-big {
    height: 65px;

    &:hover {
      height: 65px;
    }
  }

  .btn-dim {
    @include btn-custom($btn-edit);
    background-color: transparent;
    border-color: rgba($color, 0.5);
    color: rgba($color, 0.5);
    min-width: 150px;

    &:hover {
      background-color: $color;
      border-color: $color;
      color: #fff;
    }
  }

  .btn-edit,
  .btn-edit:hover {
    @include btn-custom($btn-edit);
  }

  .btn-disable,
  .btn-disable:hover {
    @include btn-custom($btn-disable);
    color: $disable-color;
  }

  .btn-reset {
    // background-color: $default-color;
    // color: inherit;
    min-width: inherit;
  }

  .btn-register {
    width: 250px !important;
  }

  a.btn-withicon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: $color;
  }

  .profile-pic {
    @include profile-pic(40px, 40px);
  }

  .creator-photo {
    @include profile-pic;

    img,
    svg {
      border: none;
    }
  }

  .attribute-title {
    font-family: "KardustCondensed";
    font-size: 18px;
    font-weight: 500;
  }

  .attribute-value {
    max-width: 50%;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #bf96ff;
    font-family: "KardustCondensed";

    &.score-down {
      color: $score-down-color !important;
      background: $score-down-color !important;
      -webkit-background-clip: text !important;
    }

    &.score-up {
      color: $score-up-color !important;
      background: $score-up-color !important;
      -webkit-background-clip: text !important;
    }
  }

  .item-icon {
    width: 20px;
    margin-right: 5px;
  }

  .token-cell {
    img {
      @include thumbnail;
      margin-right: 15px;
    }

    .name {
      // font-weight: bold;
      text-decoration-line: underline;
      word-break: break-word;
    }
  }

  .money {
    text-align: right;
    justify-content: flex-end;
  }

  .td-status {
    font-weight: bold;
  }

  .contract {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: $btn-color-4;
      margin-bottom: 5px;
    }

    .btn {
      background-color: $btn-color-4;
    }
  }

  .span-custom-1 {
    color: $btn-color-4;
  }

  .view-more {
    @include gradient-text-icon-hover(url(../../assets/icons/plus_circle_default.svg),
      url(../../assets/icons/plus_circle_gradient.svg));
  }

  .user-icon {
    @include gradient-icon-hover(url(../../assets/icons/user_default.svg),
      url(../../assets/icons/user_gradient.svg));
  }

  .bell-icon {
    @include gradient-icon-hover(url(../../assets/icons/bell_default.svg),
      url(../../assets/icons/bell_gradient.svg));
  }

  .wallet-icon {
    @include gradient-icon-hover(url(../../assets/icons/wallet.svg),
      url(../../assets/icons/wallet_gradient.svg));
  }

  #mainNav {
    @include media-breakpoint-up("lg") {
      padding: 12px 0 !important;
    }

    .navbar-toggler {
      margin-left: 18px;
      border: none;
      padding: 0;
      margin-top: -3px;
    }

    #navbarSupportedContent.navbar-collapse {
      @include media-breakpoint-down("lg") {
        @include flex-center;

        width: 100vw;
        height: 100vh;
        position: absolute;
        top: -100vh;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $bg-color;
        transition: all 0.5s ease-in-out;
        transform-origin: left top;
        // transform: scaleY(0);

        &.show {
          // transform: scaleY(1);
          top: 0;
        }

        .nav-link {
          margin-left: 0;
        }

        .logo {
          max-width: 160px;
          margin-top: 50px;
          margin-bottom: 50px;
        }
      }

      .navbar-nav {
        @include media-breakpoint-down("lg") {
          flex-direction: column !important;
          margin-left: unset !important;
          width: 100vw;
          height: 100vh;
          max-height: 500px;
          padding-top: 40px;
          justify-content: space-around;
        }
      }
    }

    .nav-item {
      margin-bottom: 0;

      .nav-link {
        margin-left: 18px;
        margin-right: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color;

        // &:hover {
        //     @include text-rainbow;
        // }

        img {
          width: 30px;
          height: 30px;

          .icon {
            width: auto;
            height: 26px;
          }
        }
      }

      .nav-link:last-child {
        margin-right: 0;
        padding-right: 0;
      }

      .icon {
        width: 26px;
      }

      &.close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    @include media-breakpoint-down("md") {
      .navbar-nav {
        flex-direction: row !important;
        justify-content: space-evenly;
      }
    }
  }

  #subNav {
    margin-bottom: 40px;
    z-index: 2;
    min-height: 34px;

    ul.navbar-nav {
      display: flex;
      flex-direction: row;
      float: left;

      li {
        margin-right: 30px;

        @include media-breakpoint-down("sm") {
          margin-right: 26px;
        }
      }

      .nav-item {
        margin-bottom: 0px;

        .nav-link {
          font-weight: bold;

          // &.active.border-bottom {
          //     border-bottom: 5px solid $tab-color  !important;
          // }
        }
      }

      &.nav-pills {
        .nav-item {
          a {
            width: 140px;
            text-align: center;
            padding: 4px;
          }
        }
      }
    }

    .external-link {
      float: right;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  #subNav.empty {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .card {
    background-color: #32304e;
    border-radius: 10px;
  }

  .token-type-img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    object-position: center;
  }

  .transaction-item {
    small {
      font-size: 15px;
    }
  }

  .topic-list,
  .trend-list,
  .token-list {
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;

    a {
      max-width: 210px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      padding: 0px;

      @include media-breakpoint-down("sm") {
        width: 50%;
        margin-right: 0;
        padding: 0 5px;
      }
    }
  }

  .item-card {
    min-width: 165px;
    min-height: 240px;
    border: none;

    @include media-breakpoint-down('xsm2') {
      min-width: unset;
    }

    &.game-card {

      min-height: 250px !important;

      svg {
        margin-top: 4px;

      }

      .price {
        @extend .subHeader;
        @extend .f-regular;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 20px;
      }

      @include media-breakpoint-down('sm') {
        svg {
          width: 30px;
        }

        .price {
          font-size: 18px;
        }
      }
    }

    &.news-card {
      // background: transparent;
      border: none;
      min-height: 235px !important;
      max-width: 230px;

      .card-image,
      .card-image img {
        border-radius: 5px;
      }
    }

    p,
    span,
    a {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .span-btn {
      height: 28px;
      border-radius: 5px;
      padding: 5px 10px !important;
      text-overflow: unset;
      pointer-events: none;
    }

    .date {
      text-overflow: unset;
    }

    .card-header {
      background-color: transparent;
      border: none;
      position: absolute;
      width: 100%;
    }

    .card-image {
      height: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      img {
        width: 100%;
        max-height: 176px;
        pointer-events: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include thumbnail("md");

        &:not(.carousel-img) {
          @include media-breakpoint-up("md") {
            @include thumbnail("lg");
          }
        }

        &.carousel-img {
          height: 120px;
        }
      }

      @include media-breakpoint-down("lg") {
        // height: 116px;
      }

      @include media-breakpoint-down("sm") {
        // height: 90px;
      }
    }

    .card-body {
      padding: 10px;
      color: #fff;

      .category-btn {
        font-size: 10px !important;
        font-family: "KardustCondensed" !important;
        max-width: 100px !important;
        overflow: hidden;
        text-overflow: ellipsis !important;
        display: inline-block !important;
        padding: 5px 7px 5px !important;
      }

      @include media-breakpoint-down("xsm2") {

        span,
        a {
          font-size: 12px !important;
        }

        div {
          font-size: 14px !important;
        }

        .category-btn {
          max-width: 65px !important;
        }

        .span-btn {
          padding: 5px 7px;
        }
      }
    }

    .profile-pic {
      @include profile-pic;
    }
  }

  .tag {
    @include tag;
  }

  img.icon {
    width: 15px;
    object-fit: contain;
    object-position: center;
  }

  .bi-inline {
    font-size: 20px;
    color: $color !important;
  }

  .item-detail {
    // background-color: $bg-color-2;
    // border-radius: 5px;

    @media (min-width: 1108px) and (max-width: 1308px) {
      .flip-card-col {
        flex: 0 0 auto;
        width: 360px;
      }
    }

    @include media-breakpoint-up('sm') {
      .flip-card-col {
        min-width: 358px;
      }
    }

    .bi {
      color: $icon-color;
      font-size: 13px;
    }

    .lock {
      color: #ffe600;

      .icon {
        fill: #ffe600;
        width: 12px;
      }

      span {
        font-size: 12px;
      }
    }

    .flip-card {
      cursor: pointer;

      .absorb-count-container {
        @include flex-center;
        justify-content: space-between;
        background-color: #26233D;
        width: 159px;
        height: 31px;
        padding: 0px 12px 0px 7px;
        border-radius: 10px;

        svg.icon {
          width: 20px;
          height: 20px;
          fill: $btn-color-cancel;
          margin-right: 5px;
        }

        .absorb-count {
          width: 15px;
          height: 15px;
          border-radius: 50px;
          border: 1px solid $btn-color-cancel;
          opacity: 0.5;

          &.active {
            background: $btn-color-cancel;
            opacity: 1;
          }
        }
      }

      .card-body {
        // padding: 21px 24px 10px;
        color: #fff;
      }

      .profile-pic {
        @include profile-pic(49px, 49px);

        &.element-frame {

          .element_fadeInOut {
            position: relative;
            margin: 0 auto;
            width: 49px;
            height: 49px;
            @include flex-center;

            .element_fadeInOut_item {
              position: absolute;
              left: 0;
              -webkit-transition: opacity 1s ease-in-out;
              -moz-transition: opacity 1s ease-in-out;
              -o-transition: opacity 1s ease-in-out;
              transition: opacity 1s ease-in-out;

              &.top {
                animation-name: cf1FadeInOut;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-duration: 5s;
                animation-direction: alternate;
              }
            }
          }
        }

        @keyframes cf1FadeInOut {
          0% {
            opacity: 1;
          }

          45% {
            opacity: 1;
          }

          55% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }
      }

      .card-image {
        img {
          width: 100%;
          height: 360px;
          object-fit: cover;
          object-position: center;
        }
      }

      .card-input {
        padding: 8px 0;
      }

      .card-input-text {
        background-color: transparent !important;
        border: none !important;
      }

      .price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 20px;
      }

      .attribute-value {
        @extend .subHeader;
      }
    }

    .card.flip-card {}

    .flip-card-body {
      background-color: transparent !important;
      border: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .feature-card {
      width: 100%;
      padding: 3px 8px !important;
      background-color: transparent;
      height: 55px;
      font-size: 16px;
      margin-bottom: 24px;
      @include border-box(#BF96FF,
        1px,
        10px);
      display: flex;
      align-items: center;
    }

    .detail {
      padding: 20px 0;
    }

    .card {
      border-radius: 5px;
      margin-bottom: 1.5rem;

      .icon {
        height: 24px;
      }
    }

    .cancel {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      min-width: 77px;
    }

    .enter {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .bidding-complete {
    .btn {
      width: 200px;
      margin-bottom: 20px;
    }
  }

  .selling-price-confirmation {
    max-width: 700px;
    background-color: white;
    border-radius: 5px;
    padding: 40px 87px;
    margin-top: 50px;

    @include media-breakpoint-down("sm") {
      padding: 15px;
    }

    .description {
      span {
        background-color: $bg-color;
        z-index: 99;
        word-break: break-word;
      }
    }

    .card {
      border: 2px solid $color;
      padding: 14px;
    }

    .btns {
      margin-top: 100px !important;
    }
  }

  .login {
    height: $heightWithoutNav;
    background: linear-gradient(90deg, $bg-color 50%, $bg-card-color 50%);
    @include flex-center;

    .login-card {
      display: flex;
      flex-direction: row;
      width: 70%;
      justify-content: center;
      align-items: center;
      background-color: $bg-color;
      box-shadow: 5px 0px 11px #00000025;
      padding: 47px;

      .btn {
        min-width: 250px;
      }

      &.default {
        width: unset;
        padding: unset;
      }

      @include media-breakpoint-down("sm") {
        width: 95%;
        padding: 15px;
      }

      .content {
        width: 100%;
        max-width: 400px;
        height: 450px;

        .login-bg {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: left;
          background-repeat: no-repeat;
        }

        .card {
          padding: 30px 30px;
          width: 100%;
          height: 100%;
        }

        .anicana-logo {
          width: 100px;
        }
      }
    }

    .box {
      // height: 100vh;
      border: none;
      width: 100%;
      max-width: 450px;
    }

    form {
      width: 100%;
      max-width: 400px;
      @include flex-center;
      flex-direction: column;
    }

    a.btn-100 {
      width: 100%;
      max-width: 400px;
      border-radius: 5px;
    }
  }

  .register {
    height: $heightWithoutNav;

    .box {
      border: none;
      width: 100%;
      // max-width: 700px;

      .wizard {
        width: 500px;
        max-width: 90vw;
      }
    }
  }

  .modal-box {
    // border: 3px solid $color;
    background: $bg-color;
    border-radius: 15px;
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 20px;
    width: 100%;
    max-width: 500px;
  }

  .modal-content {
    min-width: 375px;
    max-width: 500px;
  }

  @media screen and (max-width:480px) {
    .modal-content {
      min-width: 250px !important;
    }
  }

  .break-word {
    overflow-wrap: break-word;
  }

  .float-center {
    @include float-center;
  }

  .card {
    &.number-card {
      width: 100%;
      height: 480px;
      border-radius: 20px;
      background-color: $bg-card-color;
      border: none;
      box-shadow: 1px 1px 5px rgba($border-color, 0.7);

      .btn {
        width: 100%;
        max-width: 250px;
        height: 50px;
      }

      &.default {
        background-color: $default-color;
      }

      .number {
        @include number-circle;
        position: absolute;
      }

      .card-body {
        padding-top: 126px;

        .stick-bottom {
          position: absolute;
          bottom: 50px;
          width: 100%;
          left: 0;
          @include flex-center;
        }
      }
    }
  }

  .stepper-container {
    width: 100%;
    max-width: 800px;

    a {
      font-size: 15px !important;
    }
  }

  .account {
    .profile-pic {
      width: 150px;
      height: 150px;
    }
  }

  .custom-popover {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .popover-container {
    z-index: 9999;

    @include media-breakpoint-down("lg") {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    @include media-breakpoint-up("lg") {
      padding: 10px;
      position: absolute;
      top: 45px;
      right: 0px;
      width: 350px;

      &.noti {
        width: 458px;
      }
    }

    .body {
      background: linear-gradient(180deg, $color 0%, #21283c 100%);
      color: #fff;
      border-radius: 0px;
      padding: 25px 20px;
      height: $heightWithoutNav;
      overflow: scroll;

      @include media-breakpoint-down("lg") {
        height: 100vh;
      }
    }

    &.noti {
      .body {
        height: auto;
        max-height: $heightWithoutNav;

        @include media-breakpoint-down("lg") {
          height: 100%;
          max-height: 100%;
        }
      }

      .pill {
        background-color: #fff;
        border-radius: 5px;
        color: $color;
        padding: 0 5px;

        .notice {
          font-size: 15px;
        }
      }

      .date {
        font-size: 17px;
      }

      p {
        font-size: 15px;
      }
    }

    &.search-popover {
      .body {
        background: $bg-color;
        padding: 0;
      }
    }

    .accordion {
      background-color: transparent !important;
      padding: 0px;

      .accordion-item {
        background-color: transparent !important;
        color: #fff;
        border: none;

        a.accordion-button {
          background-color: transparent !important;
          color: #fff;
          color: inherit !important;
          text-decoration: none;
          box-shadow: none;
          padding: 8px 20px;

          &.collapsed::after {
            background-image: none;
            transform: none;
          }

          &::after {
            background-image: url(../../assets/icons/chevron_down.svg);
            background-size: 10px;
            transform: none;
          }
        }

        .accordion-body {
          padding: 5px 20px;

          ul {
            list-style: none;
            margin-bottom: 8px;

            li {
              cursor: pointer;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .logout {
      position: absolute;
      bottom: 30px;

      @include media-breakpoint-down("lg") {
        bottom: 15px;
      }
    }
  }

  .guide {
    .card {
      .card-body {
        background: $color;

        p {
          margin-bottom: 0;
        }
      }
    }

    .accordion {
      .accordion-item {
        color: #000;

        a.accordion-button {
          color: #000;

          &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          }
        }
      }
    }
  }

  .nav-tabs,
  .nav-pills {
    // border-bottom: none;
    // background-color: transparent;
    display: flex;
    justify-content: space-between;

    .nav-item {
      max-width: 150px;

      .nav-link {
        // color: $color;
        // border-radius: 0;
        // border-bottom: transparent;

        &:not(.active) {
          background-color: transparent;
          color: rgba($color, 0.5);
          border: 1px solid rgba($color, 0.5);
        }

        &.active {
          background-color: $color;
          color: #fff;
          border: 1px solid $color;
        }

        &:hover {
          // border: none;
          // border-bottom: 5px solid $tab-color;
          // background-color: transparent;
        }
      }

      &:hover {
        border: none;
      }
    }
  }

  .guide .nav-pills {
    border: 1px solid $border-color;
    border-radius: 15px;
    min-height: 480px;
    padding: 20px;

    .nav-link {
      color: $color;
      border-radius: 0;

      &.active,
      &:hover {
        border: none;
        background-color: transparent;
        color: $color;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  .tab-content {
    &.vertical {
      padding-left: 60px;

      @include media-breakpoint-down("sm") {
        padding-left: inherit;
      }
    }
  }

  .footer {
    .content {
      text-align: center;
      // max-width: 700px;
      @include float-center;

      .footer-logo {
        margin-top: 100px;
        margin-bottom: 150px;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      margin: 0 16px;
    }

    .links {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    @include media-breakpoint-down("sm") {
      .links {
        a:not(:last-child) {
          margin-bottom: 40px;
        }

        a {
          width: 100%;
          // text-align: center;
        }
      }

      .copy-right {

        span {
          text-align: center;
          color: #fff;
          font-size: 20px;
          min-width: 100%;
        }
      }
    }
  }

  .bookmark-item,
  .history-item {
    background-color: $color;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    color: #fff;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-down("sm") {
        width: 100%;
        height: 200px;
      }
    }

    // .icon {
    //     position: absolute;
    //     left: 15px;
    //     top: 15px;
    // }

    .tag {
      position: relative;
      font-weight: bold;
      color: $color;
      font-size: 12px;
      border-radius: 5px;
      padding: 0 5px;
      background-color: $btn-color-2;
    }
  }

  .box {
    border: 1px solid $color;
    border-radius: 10px;
    padding: 15px;

    .image-container {
      width: 100%;
      height: 120px;
      background-color: $default-color;

      img {
        width: 100%;
        height: 120px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  ul {
    li {
      margin-bottom: 12px;
    }
  }

  label {
    display: flex;
    align-items: center;
    flex-direction: row;

    span {
      min-width: 300px;
    }
  }

  ul.pagination {
    align-items: center;

    li.page-item {
      border: none;
      display: grid;
      place-items: center;

      .page-link {
        display: grid;
        place-items: center;
        background-color: transparent;
        color: $color;
        border: none;
        text-decoration: none !important;
        font-weight: 600;
        font-family: Kardust;

        &.previous,
        &.next {
          svg {
            width: 20px;
          }
        }
      }

      &.active .page-link {
        font-size: 32px;
        // margin-top: -15px;
        font-size: 20px;
        @include text-gradient($gradient-color);
      }

      .page-number {
        text-decoration: underline;
        font-size: 12px;
      }
    }

    li.page-item-disable {
      display: none;
    }
  }

  // phase-2
  #home {
    .feature {
      background-color: $bg-color-2;
    }
  }

  .bg-global {
    position: relative;
    min-height: 300px;
  }

  .bg-global:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("../../assets/images/global.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 300px;
    max-height: 837px;
  }

  .content {
    position: relative;
  }

  .indicator-below .control-dots {
    // bottom: -40px;

    .dot {
      border: 1px solid $color;

      &.selected {
        background-color: $color;
      }
    }
  }

  .text-justify {
    text-align: justify !important;
  }

  .text-lg-center {
    @include media-breakpoint-up('lg') {
      text-align: center !important;
    }
  }

  .text-danger {
    color: $text-danger-color !important;
  }

  .text-default {
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
  }

  .text-gradient {
    font-family: KardustCondensed;
    @include text-gradient;
  }

  .text-gradient-default {
    font-family: KardustExpanded;
    @include text-gradient($gradient-color);
  }

  .text-rainbow {
    @include text-rainbow;
  }

  .text-break {
    word-break: break-all;
  }

  .text-overflow {
    @include text-overflow;
  }

  .text-overflow-vertical {
    @for $i from 1 through 10 {
      &-#{$i} {
        @include text-overflow-vertical($i);
      }
    }
  }

  #faqs {
    .accordion {
      padding: 0;

      .accordion-item {
        background-color: $bg-color-2 !important;
        border: none;

        .accordion-header {
          .accordion-button {
            background-color: $bg-color-2;
            color: $color;
            font-weight: bold;
            box-shadow: none;
            border-radius: 0;

            &::after {
              background-image: url("../../assets/icons/chevron_right_bg.svg");
              fill: $color;
              transform: rotate(0deg) scale(0.75);
            }

            &:not(.collapsed)::after {
              transform: rotate(90deg) scale(0.75);
            }
          }
        }

        .accordion-body {
          color: $color;
        }
      }
    }
  }

  #information {
    .nav-pills {
      .nav-item {
        min-width: fit-content;

        .nav-link {
          padding: 8px 36px;
        }
      }
    }

    .pill {
      background-color: $color;
      border-radius: 5px;
      color: $bg-color;
      padding: 8px 40px;
    }

    img {
      &.info_pic {
        width: 100% !important;
        height: 350px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .select,
  .fileinput {
    position: relative;

    .setting-select.form-select {
      height: 55px !important;
    }

    .form-select {
      @extend .textbox;
      background-image: none;
      padding-right: 50px;

      .form-select-option {
        background-color: #19162d;
        border: 1px solid #fff;
        padding-right: 50px;
      }
    }

    .dropdown-icon {
      position: absolute;
      top: 20px;
      right: 15px;
    }

    .dropdown-icon.setting-dropdown-icon {
      top: 20px !important;
    }
  }

  .textbox {
    @include textbox;

    &.price {
      width: 100%;
      height: auto;
      border: 1px solid $color;
      background-color: $color;
      color: #fff;
      text-align: center;
      border-radius: 0;
    }
  }

  .textbox-2 {
    @include textbox($bg-color, #fff);
  }

  .textarea {
    height: 150px;
  }

  .search-container {
    margin-left: 12px;
    position: relative;
    width: 100%;
    max-width: 568px;

    .searchbox {
      height: 37px;
      margin-bottom: 0;
      padding-right: 50px;
      padding-top: 3px;

      &:focus {
        opacity: 1;
      }
    }

    &:not(&.header-search-container, &.menu-search-container) {
      .searchbox {
        @extend .auth-form-input-absolute;
      }
    }

    &.header-search-container,
    &.menu-search-container {
      @include border-box($gradient-color, 1.5px, 10px, 1);
      padding: 1px;

      .searchbox {
        background: $search-bg-color;
        border: none;
        border-radius: 10px;
        padding-left: 50px;
        padding-right: 12px;

        &:not(.hasText) {
          @include text-gradient;
        }

        &.hasText {
          @include text-gradient(#FFF);
        }

        caret-color: #FFF;
      }
    }

    &.menu-search-container {
      .searchbox {
        padding-left: 14px;
      }
    }

    .search-icon {
      position: absolute;
      top: 9px;
      right: 14px;
    }

    .search-back-icon {
      position: absolute;
      top: 8px;
      left: 19px;
    }

    .search-history {
      position: fixed;
      width: 100%;
      max-width: 568px;
    }
  }

  .card-line-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    p {
      @extend .subHeader;
      @extend .f-monodb;
      margin-bottom: 0;

      -ms-flex: 0 0 250px;
      flex: 0 0 fit-content;

      &.title {
        font-size: 35px !important;

        &.fs-25 {
          font-size: 25px !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      border-color: $color !important;

      &.dim {
        border-color: #ffffff25 !important;

      }
    }
  }

  .price-range {
    .textbox {
      height: 38px;
    }

    p {
      margin-top: 12px;
      padding-right: 25px;
    }
  }

  .gradient {
    @include gradient-border;
  }

  .border-horizontal {
    border: 1px solid #b779f8;
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .gradient-text-hover {
    @include gradient-text-hover;
  }

  .filter {
    font-family: Kardust;
    max-width: 350px;

    .filter-accordion-body {
      padding-left: 20px;
      margin-top: 16px;

      .form-check {
        .active {
          @include text-gradient($badge-bg-color);

          svg {

            path,
            rect {
              fill: $badge-bg-color;
            }
          }
        }
      }


      ul {
        &.nav-pills {
          li.nav-item {
            margin-right: 15px;

            a.nav-link {
              color: $bg-li-color !important;
              padding: 0 15px !important;
              text-indent: 0;
              border: none;

              &.active {
                background-color: #fff !important;

                a.nav-link {
                  color: $color !important;
                }
              }
            }
          }
        }
      }
    }

    .minmaxrow {
      padding-left: 38px;

      input {
        width: 80px;
        height: 25px !important;
        font-size: 15px;
      }
    }

    .filter-action {
      background-color: transparent;
      max-width: 450px;
      margin: 0 25px 12px;
    }
  }

  .flex-nav-row {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      // display: block;
      justify-content: center;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      // width: max-content;
      width: 100%;

      li {
        margin-bottom: 0;
        margin-right: 30px;

        &:nth-of-type(4) {
          margin-right: 0;
        }

        a {
          display: flex;
          align-items: center;
          flex-direction: column;
          color: #fff !important;
          padding: 8px !important;

          .profile-pic {
            @include border-box(linear-gradient(270deg, #20b2ff 2.38%, #9000c2 97.62%));
            @include profile-pic(30px, 30px, 15px);

            svg {
              fill: url(#nav_gradient_paint);
            }

            &::before {
              padding: 2px;
              border-radius: 150px !important;
            }
          }

          &.nav-link {
            border: none !important;

            span {
              font-family: "Kardust";
              font-weight: 400px;
              font-size: 16px;
            }

            &.active {
              background-color: transparent !important;

              .profile-pic {
                @include border-box(linear-gradient(270.03deg, #9001c3 9.94%, #20f6ff 99.96%));
                @include profile-pic(30px, 30px, 15px);
                background: $gradient-color;

                svg {
                  fill: url(#nav_gradient_active_paint);
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        justify-content: space-evenly;

        li {
          margin: 0;
        }
      }
    }
  }

  .sec-bottom-nav {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    bottom: calc($bottomNavHeight);
    background-color: #fff;
    padding-bottom: 30px;

    .nav-body {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
  }

  .wallet-detail-value {
    // color: $default-color;
    font-weight: 400;
    font-family: "Kardust";
    font-size: 20px;
    margin-left: 15px;

    &.gradient-value {
      font-weight: 600;
      font-family: Kardust;
      font-size: 25px;
      background: $purple; // linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      small {
        font-size: 15px;
        margin-left: 10px;
      }
    }

    &.isWalletAddress {
      font-weight: 400;
      font-size: 18px;
      color: #fff;
      margin-left: 0;
      margin-top: 12px;
      @include text-overflow;
      @include border-box;
    }
  }

  .Overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
    background: rgba(25, 22, 45, 0.8);
    mix-blend-mode: normal;
    backdrop-filter: blur(15px);
    opacity: 0.7;
  }

  .Overlay.Show {
    display: block;
  }

  .Modal {
    box-sizing: border-box;
    background: linear-gradient(192.05deg,
        #4b225c 0%,
        #19162d 23.44%,
        #19162d 72.92%,
        #153059 100%);
    @include border-box;

    .CenterAlign {
      top: 25%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -25%);
    }

    &.modal-slideup {
      position: fixed;
      bottom: -150vh;
      width: 100%;
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
      left: 0;
      padding: 0 12px 12px;
      transition: all 0.3s ease-out;
      z-index: 10;
      padding: 47px 27px;
      border-radius: 15px 15px 0px 0px;

      &.is-mobile-safari-model {
        padding: 57px 27px;
      }

      &::before {
        border-radius: 15px 15px 0px 0px;
      }

      &.Show {
        bottom: 0;
      }

      .Close {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 10px;
        background-color: transparent;
        border: 0;
        font-size: 18px;
      }
    }
  }

  .profile-cam-icon {
    width: 35px;
    height: 35px;
  }

  .tokenlist-loading {
    align-items: flex-start;
    width: auto !important;
  }

  .loading {
    &.progress-bar {
      @include flex-center;
      // position: absolute;
      // top: 147;
      margin-top: 147px;

      .parent {
        height: 20px;
        width: 80%;
        background-color: rgba($default-color, 0.2);
        border-radius: 12px;
      }

      .child {
        height: 20px;
        min-width: 30px;
        background: $gradient-color;
        border-radius: 12px;
        @include flex-center;
      }

      .text {
        margin-bottom: 35px;
        font-weight: 500;
      }

      .message {
        margin-top: 69px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          margin-top: 65px;
        }
      }

      .tick-icon {
        opacity: 0;
      }

      &.finished {
        margin-top: 68px;
        transition: margin-top 1s linear;

        .parent {
          background-color: transparent;
        }

        .parent,
        .child {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          // transition: width .5s linear;
        }

        .message,
        .tick-icon {
          opacity: 1;
          transition: opacity 1s ease-in;
          transition-delay: 1s;
        }
      }
    }
  }

  .labs-list-font {
    font-family: 'KardustCondensed';
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
  }

  .multi-carousel-container {
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: calc(100% + 12px);
    }

    @include media-breakpoint-down('lg') {
      a {

        &:first-child {
          .multi-carousel-item {
            margin-left: 16px;
          }
        }
      }
    }
  }

  .type-text {
    background: #BF96FF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .games-each-bg {
    background: #32304E;
    border-radius: 10px;
    // height: 80px !important;
  }

  .games-price-tag-bg {
    background: #BF96FF;
    border-radius: 0px 10px 10px 0px;
  }

  .games-type {
    background: linear-gradient(270.03deg, #9001C3 9.94%, #20F6FF 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .games-detail-info {
    background: rgba(50, 48, 78, 0.85);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .each-game-detail-preview-box {
    border-radius: 10px;
    object-fit: cover;

    @include media-breakpoint-down('md') {
      width: 50%;
    }

    a {
      cursor: auto !important;
    }
  }

  .games-tag-col {
    flex: 0 0 auto;
    width: 26%;
  }
}

.switch-btn {
  border: 0;
  background: transparent;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    // padding: $border-width;
    opacity: 0.5;
    border-radius: 10px;

    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #e85eff, #2dd9ff);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.swal-react-modal {
  height: unset;
  overflow: unset;
  min-height: unset;
  background: transparent;
}

.qrModal {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.aniBtn {
  font-size: 30px !important;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

.correct-icon {
  background-image: url("../../assets/icons/gradient_correct.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.levias-correct-icon {
  background-image: url("../../assets/icons/levias-correct.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.levias-btn-custom-cancel {
  mix-blend-mode: normal;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 25px !important;
  line-height: 30px !important;
  height: 55px;
  background-color: #fff;
  border: 1px solid $levica-green;
  color: $levica-green;
  backdrop-filter: blur(10px);
  border-radius: 5px;
}

.sent-email-icon {
  background-image: url("../../assets/icons/sent-email.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
}

.levias-sent-email-icon {
  background-image: url("../../assets/icons/levias-sent-email.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
}

.sent-sms-icon {
  background-image: url("../../assets/icons/sent-sms.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
}

.wrong-icon {
  background-image: url("../../assets/icons/wrong.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.warn-icon {
  background-image: url("../../assets/icons/warning.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}

// .anikana-logo {
//   width: 30px;
//   height: 30px;
//   background: url('../../assets/images/anikana-logo.svg');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
// }

.anikana-label {
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // This font is already Monoglyceride font. 
  // Please do not add duplicate font with different name to avoid confusion. 
  // If font has to be replaced, please replace the font file or other "monodb" named font-family will not be affected.
  font-family: "Monoglyceride";
  font-size: 30px;
  min-width: 100%;
  background-image: linear-gradient(90deg, rgba(232, 94, 255, 1) 0%, rgba(45, 217, 255, 1) 100%);
}

.anikana-filters {
  color: #bf96ff;
  font-family: "KardustExpanded";
  font-size: 20px;
}

.anikana-logo-box {
  z-index: 5;
  align-items: center;
  // margin-bottom: 27px;
  position: relative;
}

.each-bottom-nav {
  // width: 22px;
  height: 23px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.w-70 {
  width: 37px;
}

.swal2-title {
  display: flex !important;
  justify-content: center;
  font-family: "Kardust";
  font-weight: 400 !important;
  font-size: 20px !important;
  text-align: center !important;
}

.levias-swal2-title {
  display: flex !important;
  justify-content: center;
  font-weight: 400 !important;
  text-align: center !important;
}

.levias-swal-id-msg {
  overflow: hidden;

  * {
    font-family: 'Hiragino Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    letter-spacing: -0.02em;
    color: #000000;
  }

  h3 {
    text-align: center;
    font-family: 'Bebas Neue' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 40px !important;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    &::first-letter {
      color: #43ADA5 !important;
    }
  }

  div {
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;

    input {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    input+label {
      position: relative;
      font-size: 19px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      height: 16px;
    }

    input+label::before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 14px;
      height: 14px;
      background-color: white;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(0, 0, 0);
      border-radius: 2px;
      box-shadow: rgba(42, 42, 42, 0) 0px 0px 5px 0px;
    }

    input:checked+label::after {
      content: " ";
      background-image: url('../../assets/images/greencheck.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      position: absolute;
      left: 1px;
      top: -1px;
      height: 16px;
      width: 16px;
      // background-position: center center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // margin-left: 0px;
      // text-align: center;
      // background-color: transparent;
      // font-size: 10px;
    }
  }
}

.text-fff {
  color: #fff !important;
}

.text-000 {
  color: #000 !important;
}

.text-f00 {
  color: #f00 !important;
}

.text-purple {
  color: $badge-bg-color !important;
}

.levias-btn-custom-active {
  position: relative;
  background: $levica-green !important;
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  border: none !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  font-family: 'Hiragino Sans' !important;
  font-size: 20px !important;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  height: 55px;
  min-width: 261px;
  padding: 0px 12px;
  padding-bottom: 5px;
  opacity: 1 !important;
}

.levias-swal2-custom-backdrop {
  color: $color;
  background: rgba(25, 22, 45, 0.9) !important;
  mix-blend-mode: normal;
  backdrop-filter: blur(2px);
}

.levias-modal-box-bordered {
  background: #fff !important;
  border-radius: 15px !important;

  &::before {
    background: #fff !important;
  }
}

.levias-wrong-icon {
  background-image: url("../../assets/icons/levias-wrong.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.swal2-html-container {
  margin: 1em 1.4em .3em;
}

.swal2-icon {
  border: 0 !important;
}

.swal2-x-mark,
.swal2-x-mark span {
  font-size: 16px;
}

.swal2-custom-backdrop {
  color: $color;
  background: rgba(25, 22, 45, 0.9) !important;
  mix-blend-mode: normal;
  backdrop-filter: blur(2px);
}

.swal2-custom-close {
  height: 21px !important;
  background: $gradient-color !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  justify-content: end;
}

#w3a-modal {
  z-index: 9999 !important;
}

.btn {
  font-family: inherit !important;
  font-size: 25px;
  // margin-top: -9px;

  &.small,
  &.small:hover {
    font-size: 20px !important;
    height: 40px;
    line-height: 19px;
  }
}

.ladda-label {
  line-height: 1;
  margin-top: 7px;
  font-size: 25px !important;
  font-weight: 400 !important;
  // font-family: "Monoglyceride" !important;
  @extend .fs-inherit;
  @include flex-center;
}

.btn-custom-success,
.btn-custom-success:hover {
  mix-blend-mode: normal;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  border-style: none;
  font-family: 'Kardust' !important;
  font-style: normal;
  font-weight: 400 !important;
  height: 55px;
  text-align: center;
  background: #82808C !important;
  ;
  display: grid;
  place-items: center;
  padding: 0 !important;
  //old code
  //opacity: 0.5;
  //border: 1px solid #ffffff !important;
  // backdrop-filter: blur(10px);
  //border-radius: 15px;
  //color: #ffffff;
  //font-family: Monoglyceride;
  //font-style: normal;
  //font-weight: 400;
  //font-size: 25px;
  //line-height: 17px;
  //height: 55px;
  //padding: 0px 12px;
  //padding-bottom: 5px;

  &:disabled {
    opacity: .4;
  }

  span {
    font-size: 25px;
  }
}

.btn-custom-success,
#submit-btn {
  font-family: 'Kardust' !important;
  font-size: 25px;
  font-family: 'Kardust' !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  min-width: 100px;
}

.btn-custom-cancel,
.btn-custom-cancel:hover,
.btn-custom-cancel:focus {
  @include btn-custom(transparent !important, $btn-color-cancel !important, 1px);
  font-family: 'Kardust' !important;
  font-weight: 400 !important;
  color: $btn-color-cancel !important;
  border-radius: 15px;
  padding: 0 !important;

  &:disabled {
    border-color: #d9d9d9 !important;
    color: #d9d9d9 !important;
  }
}

.btn-custom-filters {
  @extend .btn-custom-cancel;
  border-radius: 7px;

  @include media-breakpoint-up('sm') {
    &.filter-btn {
      min-width: 140px !important;
      width: auto !important;
    }
  }

  @include media-breakpoint-down('md') {
    height: 40px !important;
  }
}

.btn-custom-nonactive,
.btn-custom-nonactive:hover {
  @include btn-custom(transparent, transparent);
  background: #80858A !important;
  // backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 15px !important;
  color: $color !important;
  border: none ip !important;
  // @include flex-center;
  // padding: 0px 12px;
  // padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100px;
  padding-top: 4px;
}

.btn-custom-active {
  @include btn-custom(transparent, transparent);
  // @include btn-center(7px);
  // background: $gradient-color;
  background: linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%) !important;
  // background: linear-gradient(268.5deg, #ab79f8 0%, #5f49b5 99.48%) !important;
  // backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 15px !important;
  color: $color !important;
  border: none ip !important;
  padding: 0 !important;
  // @include flex-center;
  // padding: 0px 12px;
  // padding-bottom: 3px;
  // padding: 5px 12px 6px;
  min-width: 100px;
  font-family: 'Kardust' !important;
  font-size: 25px !important;
  line-height: 47px;

  &:disabled {
    // opacity: .4;
    background: #82808C !important;
  }

  span {
    // @include text-gradient($gradient-background);
    font-family: 'Kardust' !important;
    font-size: 25px !important;
    margin: 0 !important;
    line-height: inherit;
    font-weight: 400;
  }
}

.btn-see-details {
  mix-blend-mode: normal;
  // border: none;
  border-radius: 15px;
  box-sizing: border-box;
  width: 230px !important;
  height: 45px !important;

  // background: $gradient-color;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text;
  border: 1px solid #BF96FF;
  color: #BF96FF;

  font-family: Monoglyceride;
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 17px;
  height: 55px;
  // @include border-box;
  padding: 13px 12px !important;

  &:hover {
    font-size: 20px !important;
    width: 230px !important;
    height: 45px !important;
    border: none;
    // border-radius: 15px;
    // box-sizing: border-box;
    // -webkit-background-clip: text;
    // background-clip: text;
    // -webkit-text-fill-color: #fff;
    background: $hover-see-details;
  }
}

.btn-mana-address {
  mix-blend-mode: normal;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;

  color: #fff;
  background-color: $gradient-color;

  font-family: 'Kardust Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  @include border-box;
  padding: 5px 10px;

  // &:hover,
  // &::before {
  //   border: none;
  //   border-radius: 7px;
  //   box-sizing: border-box;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  //   -webkit-text-fill-color: #fff;
  //   background: $hover-see-details;
  // }
}

.btn-login {
  color: white;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  mix-blend-mode: normal;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;
  background: $gradient-color;
  font-family: 'Kardust';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  height: 30px;
  width: 100px;
  // @include border-box;

  &:hover,
  &::before {
    border: none;
    border-radius: 7px;
    box-sizing: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #000;
    background: $gradient-color;
  }
}

.btn-mini {
  min-width: 261px !important;
}

.btn-subscribe {

  .ladda-spinner {
    left: 39.5px !important;
  }
}

.btn-unsubscribe {

  .ladda-spinner {
    left: 55px !important;
  }
}

.css-1s2u09g-control,
.css-1s2u09g-control:focus {
  background-color: transparent !important;
  height: 55px !important;
  mix-blend-mode: normal;
  opacity: 0.5 !important;
  border: 1px solid #FFF !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
  margin-bottom: 20px !important;
}

.css-1pahdxg-control {
  background-color: transparent !important;
  height: 55px !important;
  mix-blend-mode: normal;
  opacity: 0.5 !important;
  border: 1px solid #FFF !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
  margin-bottom: 20px !important;
}

.css-89lswr-Sa {
  display: none !important;
}

.css-qc6sy-singleValue,
.css-qc6sy-singleValue:focus {
  color: #FFF !important;
}

.css-2613qy-menu {
  background-color: transparent !important;
  height: 55px !important;
  mix-blend-mode: normal;
  opacity: 0.5 !important;
  border: 1px solid #FFF !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
  margin-bottom: 20px !important;
}

.myVideoWrapper {
  height: 100%;
  width: 100%;

  position: fixed;
  top: calc($navHeight);
  left: $sidebarWidth;
  pointer-events: none;
  z-index: -1;
}

#myVideo {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;

  position: fixed;
  top: calc($navHeight);
  left: $sidebarWidth;
  opacity: 0.2;
  pointer-events: none;
  z-index: -1;

  @include media-breakpoint-down('lg') {
    top: 0;
    left: 0;
  }
}

.richtextbody {
  img {
    max-width: 100%;
  }
}

.phoneInput {

  .textbox,
  &-2 {
    background-color: transparent !important;
    height: 55px !important;
    mix-blend-mode: normal !important;
    opacity: 1 !important;
    // border: 1px solid rgba(217, 217, 217, 0.5) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    border-radius: 15px !important;
    margin-bottom: 20px !important;
    color: #FFF !important;
    font-family: KardustExpanded;
  }

  .flag-dropdown,
  .flag-dropdown.open {
    @include textbox;
    opacity: 1;
    border: none !important;
    background: transparent !important;
    backdrop-filter: unset;

    .selected-flag {
      width: 50px;
      padding-left: 15px;
      background-color: transparent !important;

      .arrow {
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
        border-top: 7px solid #fff !important;

        &.up {
          border-top: none !important;
          border-bottom: 7px solid #fff !important;
        }
      }
    }
  }

  .country-list {
    background: $gradient-background;

    .country {

      &.highlight,
      &:hover {
        background-color: #f1f1f121 !important;
      }
    }

    span {
      font-family: KardustExpanded;
      font-size: 15px;
    }
  }
}

.phoneInputDisplay {
  position: relative;
  width: calc(100% - 60px);
  height: 100%;
  float: right;
  background-color: transparent;
  color: $color;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 12px;
}

.phoneInput.textbox,
.phoneInputDisplay {
  height: 55px !important;
}

.phoneInput-2 {
  margin-bottom: 0px !important;

  input {
    background: transparent;
    color: $color;
    height: 55px;
    border: none;
    width: 100%;
  }

  .PhoneInputCountry {

    padding-left: 15px;
    padding-right: 15px;

    .PhoneInputCountryIcon--border {
      width: 43px;
      height: auto;
      background: transparent;
      box-shadow: none;

      img {
        border: 1px solid #C4C4C4;
        border-radius: 5px;
      }
    }

    select {
      background-image: url(../../assets/icons/chevron_down.svg);
    }
  }
}

.swal2-input {
  z-index: 9999;
}

.twoFa-switch-off {
  width: 67px;
  height: 31px;

  .react-switch-bg {
    height: 30px !important;
    width: 67px !important;
    background: transparent !important;

  }

  .react-switch-handle {
    background: #BF96FF !important;
    top: 4px !important;
    left: 2px !important;
  }
}

.twoFa-switch-off::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  background: #BF96FF;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  color: #ffffff;
  border: solid 2px transparent;
}

.twoFa-switch-on {
  width: 67px;
  height: 31px;

  .react-switch-bg {
    height: 30px !important;
    width: 67px !important;
    background: #BF96FF !important;
  }

  .react-switch-handle {
    background: #FFF !important;
    top: 4px !important;
    left: 2px !important;
  }
}

.twoFa-switch-on::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  background: #BF96FF;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  color: #ffffff;
  border: solid 2px transparent;
}

.modal-box-bordered {
  @include border-box($gradient-color, 1px);
  position: relative !important;
  padding: 16px 12px !important;
  background: $gradient-background !important;
  border-radius: 15px !important;

  @include media-breakpoint-up('lg') {
    // max-width: 40%;
  }

  .btn-mini {
    min-width: 210px !important;
  }
}

.auth-form-input-absolute {
  @include auth-form-input( !important);
}

input.datepicker {
  @include auth-form-input( !important);

}

.progressLoadingSymbol {
  // background: $gradient-color;
  background-image: url('../../assets/icons/tick-gradient.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px !important;
  height: 17px !important;
}

// @include media-breakpoint-up('lg') {
.wp-30 {
  width: 30%;
}

.wp-15 {
  width: 15%;
}

.wp-resp-30 {
  img {
    //width: 30% !important;
    object-fit: contain;
  }
}

// }

/* Change the white to any color */
input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  border: solid 2px #797783 !important;
}

// input:-webkit-autofill:focus {
//   border: solid 2px #E85EFF !important;
// }
.h-13 {
  height: 13px !important;
}

.h-176 {
  height: 176px !important;
}

.input-desc-text {
  font-size: 13px !important;
}

.arcanagen {
  font-family: Kardust;
  max-width: 1112px;

  .arcana-image-card-main {
    width: 341px;
    height: 554px;

    .arcana-image-card-header {
      margin-bottom: 26.5px !important;
    }

    .arcana-image-card-info {
      margin-top: 14.78px !important;
    }

    .card-body {
      padding-left: 23px;
      padding-right: 23px;
    }

    .arcana-image-card-photo {
      width: 296px;
      height: 362px;
    }
  }

  .arcana-gen-form {
    @include media-breakpoint-up('lg') {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 554px;
      padding-top: 14px;
    }
  }

  .fileinput {
    .dropdown-icon {
      top: 12px !important;
    }
  }

  .textbox {
    height: 45px;
    opacity: 1;
    border: 2px solid rgba($border-color, 0.7);
    -webkit-background-clip: padding-box;
    /* for Safari */
    background-clip: padding-box;
    /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .border-box {
    padding: 10.5px 12px;
    width: 100% !important;
  }

  .wallet-address-title {
    margin-bottom: 0 !important;
  }

  textarea {
    min-height: 177px !important;
  }

  @include media-breakpoint-custom-between(1106px, 1200px) {

    .custom-label,
    .wallet-address-title {
      font-size: 18px !important;

      span {
        font-size: 18px;
      }
    }

    textarea {
      min-height: 172px !important;
    }
  }

  @include media-breakpoint-custom-between(1042px, 1106px) {

    .custom-label,
    .wallet-address-title {
      font-size: 16px !important;

      span {
        font-size: 16px;
      }
    }

    textarea {
      min-height: 172px !important;
    }
  }

  @include media-breakpoint-custom-between(992px, 1043px) {

    .custom-label,
    .wallet-address-title {
      font-size: 13px !important;

      span {
        font-size: 13px;
      }
    }

    textarea {
      min-height: 170px !important;
    }
  }

  .input-desc-text {
    @include media-breakpoint-custom-between(992px, 1035px) {
      font-size: 11px !important;
    }
  }

  @include media-breakpoint-between('lg', 'xl') {
    .ladda-label {
      font-size: 20px !important;
    }
  }
}

.progress-bar-title {
  @include media-breakpoint-down('sm') {
    max-width: 200px;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.border-box {
  @include border-box;
}

.border-left-purple {
  border-left: 1px solid $purple;
}

.password-box {
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-hide-password-icon {
  position: absolute !important;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 15px;
}

.show-password-icon {
  right: 16px;
}

.profile-pic-general {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-acc-pic {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50%;
  cursor: auto;
}

.over-h {
  overflow: hidden;
}

.sidebar-footer {
  font-family: 'KardustExpandedLight';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}

.sidebar-footer-copy-right {
  // position: absolute;
  // bottom: 11vh;
  pointer-events: none;
  font-family: "Noto Sans JP" !important;
  // line-height: 17px;
  background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.close-btn {
  stroke: #BF96FF !important;
  right: 28px;
  cursor: pointer;
}

.padding-0 {
  padding: 0 !important;
}

.mw-container {
  max-width: 480px;
  width: 100%;
}

.testing {
  min-height: 70px;
  max-height: 250px;
  overflow: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn:not(.btn-custom-cancel, .btn-custom-cancel-sm):focus,
input.searchbox:focus,
.swal2-custom-close:focus input:focus,
.swal2-custom-close-jp:focus,
.swal2-close:focus {
  border-color: transparent !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0) !important;
  outline: 0 none !important;
}

.swal2-close:hover {
  color: transparent;
}

.swal2-settings-actions {
  justify-content: space-between;
  margin-bottom: 40px;

  .swal2-confirm {
    margin-right: 6px;
  }

  .swal2-cancel {
    margin-left: 6px;
  }
}

// input:focus-visible {
//   border-color: transparent !important;
//   outline: 0 none !important;
// }

#levias,
#levias-pc {
  .icon {
    fill: $levica-green;
  }
}

// Levias SP UI CSS
#levias,
.levias-container {
  font-family: 'Hiragino Sans' !important;
  margin-bottom: 1000px;

  .levias-block {
    min-height: 500px;
  }

  .mainbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 150px !important;
  }

  .auth-form-container {
    max-width: 450px;
  }

  .levias-app-wrapper {
    color: #000;
    background: $levias-background;

    &::before {
      background-image: url(../../assets/images/levias-bg-logo.svg);
      opacity: 0.05;
      content: "";
      position: fixed !important;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      mix-blend-mode: normal;
      z-index: 0;
      pointer-events: none;
    }

    .levias-logo {
      width: 102px;
      height: 33px;
      background: url('../../assets/images/levias-icon-logo.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .levias-logo-box {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #000000;
    cursor: pointer;
    min-width: 60px;
    justify-content: end;
  }

  .btn {

    &.small {
      font-size: 20px !important;
      height: 40px;
      line-height: 19px;
    }
  }

  .levias-btn-custom-success {
    background: #80858A !important;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1 !important;

    &:hover {
      background: $levica-green !important;
    }
  }

  .levias-btn-custom-active,
  .levias-btn-custom-active:hover {
    position: relative;
    background: $levica-green !important;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1 !important;

    &::before {
      @include levias-before-line;
    }
  }

  .levias-btn-custom-cancel {
    mix-blend-mode: normal;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    line-height: 30px !important;
    height: 55px;
    background-color: #fff;
    border: 1px solid $levica-green;
    color: $levica-green;
    backdrop-filter: blur(10px);
    border-radius: 5px;
  }

  .levias-btn-custom-active-no-before {
    position: relative;
    background: $levica-green;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none;
    border-radius: 5px;
    color: #ffffff;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1;
  }

  .levias-btn {
    font-family: 'Hiragino Sans' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
  }

  .levias-auth-form-input-absolute {
    @include levias-auth-form-input( !important);
    display: flex !important;
    font-family: 'Hiragino Sans' !important;
    background-color: rgb(255, 255, 255);
    &.phoneInput-2 input {
      color: #000 !important;

      &:focus,
      &.focus {
        &::placeholder {
          color: transparent !important;
        }
      }

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 18px !important;
        color: #C4C4C4 !important;
      }
    }
  }

  .levias-create-account {
    .levias-create-account-label {
      font-style: normal;
      font-weight: 500;
      font-size: 15px !important;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $levias-color;
    }

    .levias-create-account-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 20px !important;
    }
  }

  .levias-kardust-light {
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;

    &.fs-14 {
      font-size: 14px !important;
    }

    &.fs-16 {
      font-size: 16px !important;
    }

    &.fs-25 {
      font-size: 25px !important;
    }

    &.fw-400 {
      font-weight: 400 !important;
    }

    &.levias-green {
      font-weight: 600;
      color: $levica-green !important;
    }
  }

  .levias-kardust-light-font {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    padding-right: 30px;

    &::before {
      content: "" !important;
      position: absolute !important;
      background-color: $levica-green !important;
      width: 25.29px !important;
      height: 1px !important;
      right: 0 !important;
      top: 50% !important;
    }
  }

  .levias-auth-form-label {
    font-family: 'Hiragino Sans' !important;
    padding: 0 0 13px 13px;
    margin: 0 0 0 61px;
    border-bottom: 2px solid $levica-green;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 22px !important;
    line-height: 22.72px;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #000;
    border: none !important;
  }

  .pincode-input-container {
    .pincode-input-text {
      box-sizing: border-box !important;
      width: 35px !important;
      height: 50px !important;
      background: #FFFFFF !important;
      border: 1px solid $levica-green !important;
      border-radius: 5px !important;
      color: $levica-green !important;
      font-size: 13px !important;
      font-family: none !important;
      font-size: 30px !important;
    }
  }

  .background-blur {
    background: rgba(25, 22, 45, 0.8);
    mix-blend-mode: normal;
    backdrop-filter: blur(15px);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }

  .cancel-icon {
    cursor: pointer;
  }

  .levias-bottom-modal {
    background: #fff;
    bottom: 0;
    left: 0;
    border-radius: 15px 15px 0px 0px;
    width: 100%;

    &::before {
      background: #fff;
      content: "";
      position: absolute;
      inset: 0;
      padding: 1.5px;
      opacity: 0.5;
    }
  }

  .levias-footer {

    @include media-breakpoint-down('sm') {
      margin-top: 80px;

    }

    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    * {
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: $levias-color;
    }

    .levias-mb-5 {
      margin-bottom: 1rem;
    }
  }

  .mr-47 {
    margin-right: 47px;
  }

  .w-40px {
    width: 40px;
  }
}


// Levias PC UI CSS
#levias-pc {
  font-family: 'Hiragino Sans' !important;

  .levias-pc-block {
    position: relative;
    z-index: 0;
    padding: 57px 89px;
    overflow: hidden !important;
    box-sizing: border-box;

    max-width: 800px;
    height: 680px;
    width: 493px;
    right: inherit;
    background: rgba(255, 255, 255, 0.3);
    mix-blend-mode: normal;
    border: 1.5px solid #43ADA5;
    border-radius: 20px;

    .levias-pc-blur {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(7.5px);
      z-index: -1;
    }
  }

  .levias-pc-block-term-and-privacy {
    max-width: none !important;
    height: unset !important;
    width: unset !important;
    border: none !important;
  }

  .mainbody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;

    @include media-breakpoint-down('ll') {}
  }

  .mainbody-term-and-pripacy {
    display: block !important;
  }

  .mt-5 {
    margin-top: 1rem !important;
  }

  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .min-height-40 {
    min-height: unset !important;
  }

  .auth-form-container {
    max-width: 450px;
    margin-top: unset;
  }

  .levias-app-wrapper {
    color: #000;
    background: $levias-background;
    position: relative !important;

    &::before {
      background-image: url(../../assets/images/levias-pc-bg-logo.svg);
      background-size: 100%;
      content: "";
      position: absolute !important;
      width: 100%;
      height: 100%;
      left: 0;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      mix-blend-mode: normal;
      z-index: 0;
      pointer-events: none;
    }

    .levias-logo {
      visibility: hidden;
    }
  }

  .levias-logo-box {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #000000;
    cursor: pointer;
    min-width: 60px;
    justify-content: end;
  }

  .btn {
    font-family: unset !important;
    font-size: 30px;

    &.small {
      font-size: 20px !important;
      height: 40px;
      line-height: 19px;
    }
  }

  .levias-btn-custom-success {
    background: #80858A !important;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1 !important;

    &:hover {
      background: $levica-green !important;
    }
  }

  .levias-btn-custom-active,
  .levias-btn-custom-active:hover {
    position: relative;
    background: $levica-green !important;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1 !important;

    &::before {
      @include levias-before-line;
    }
  }

  .levias-btn-custom-cancel {
    mix-blend-mode: normal;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    line-height: 30px !important;
    height: 55px;
    background-color: #fff;
    border: 1px solid $levica-green;
    color: $levica-green;
    backdrop-filter: blur(10px);
    border-radius: 5px;
  }

  .levias-btn-custom-active-no-before {
    position: relative;
    background: $levica-green;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border: none;
    border-radius: 5px;
    color: #ffffff;
    line-height: 30px;
    height: 55px;
    padding: 0px 12px;
    padding-bottom: 5px;
    opacity: 1;
  }

  .levias-btn {
    font-family: 'Hiragino Sans' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    max-width: 295px !important;
  }

  .levias-auth-form-input-absolute {
    @include levias-auth-form-input( !important);
    display: flex !important;
    font-family: 'Hiragino Sans' !important;
    font-size: 18px !important;
    background-color: rgb(255, 255, 255);

    &.phoneInput-2 input {
      color: #000 !important;

      &:focus,
      &.focus {
        &::placeholder {
          color: transparent !important;
        }
      }

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 18px !important;
        color: #C4C4C4 !important;
      }
    }
  }

  .levias-create-account {
    max-width: 295px;

    .levias-create-account-label {
      font-style: normal;
      font-weight: 500;
      font-size: 15px !important;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $levias-color;
    }

    .levias-create-account-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 20px !important;
    }
  }

  .levias-kardust-light {
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;

    &.fs-16 {
      font-size: 16px !important;
    }

    &.fs-25 {
      font-size: 25px !important;
    }

    &.fw-400 {
      font-weight: 400 !important;
    }

    &.levias-green {
      font-weight: 600;
      color: $levica-green !important;
    }
  }

  .levias-kardust-light-font {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    padding-right: 30px;
    cursor: pointer;

    &::before {
      content: "" !important;
      position: absolute !important;
      background-color: $levica-green !important;
      width: 25.29px !important;
      height: 1px !important;
      right: 0 !important;
      top: 50% !important;
    }
  }

  .levias-auth-form-label {
    margin-top: 56px !important;
    font-family: "Hiragino Sans" !important;
    font-weight: 400 !important;
    line-height: 27.72px;
    font-size: 22px !important;
    text-align: center;
    text-transform: none;
  }

  .levias-pc-pt-1 {
    padding-top: 1rem !important;
  }

  .levias-pc-max-height-360 {
    max-height: 360px;
    overflow: scroll;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #000;
    border: none !important;
  }

  .pincode-input-container {
    .pincode-input-text {
      box-sizing: border-box !important;
      width: 35px !important;
      height: 50px !important;
      background: #FFFFFF !important;
      border: 1px solid $levica-green !important;
      border-radius: 5px !important;
      color: $levica-green !important;
      font-size: 13px !important;
      font-family: none !important;
      font-size: 30px !important;
    }
  }

  .background-blur {
    background: rgba(25, 22, 45, 0.8);
    mix-blend-mode: normal;
    backdrop-filter: blur(15px);
    width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
  }

  .cancel-icon {
    cursor: pointer;
  }

  .levias-bottom-modal {
    background: #fff;
    bottom: 0;
    left: 0;
    border-radius: 15px 15px 0px 0px;
    width: 100%;

    &::before {
      background: #fff;
      content: "";
      position: absolute;
      inset: 0;
      padding: 1.5px;
      opacity: 0.5;
    }
  }

  .levias-footer {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 20px;
    justify-content: space-around;

    * {
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px !important;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $levias-color;
    }
  }

  .mr-47 {
    margin-right: 47px;
  }

  .w-40px {
    width: 40px;
  }
}

.mobileNav {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;
  margin-right: -15px;

  .mobileNavItem,
  .mobileNavItemActive {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Kardust';
    @include flex-center;
    margin-left: 15px;
    height: 30px;
    line-height: 0;
  }

  .mobileNavItem {
    @extend .btn-custom-cancel;
    @include border-box($gradient-color, 1.5px, 7px);
  }

  .mobileNavItemActive {
    background: $gradient-color;
    padding: 16px 12px;
    border-radius: 7px;
  }

}

.headerIcon {
  width: 30px;
  height: 30px;
}

.itemHeader {
  font-family: 'Kardust' !important;
  font-weight: 500 !important;
  background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #bf96ff;

  @include media-breakpoint-down('sm') {
    font-size: 23px !important;
  }
}

.lineHeaderLeft {
  @include media-breakpoint-down('lg') {
    display: none !important;
  }
}

.lg-visibility-hidden {
  @include media-breakpoint-up('lg') {
    visibility: hidden;
  }
}

.wh-23px-important {
  width: 23px !important;
  height: 23px !important;
}

.wh-25px {
  width: 25px;
  height: 25px;
}

.wh-25-h-39 {
  width: 25px;
  height: 39px;
}

.w-150px {
  width: 150px;
}

.w-330 {
  width: 330px !important;
}

.h-40px {
  height: 40px;
}

.ml-40px {
  margin-left: 40px;
}

.add-form {
  max-width: 320px;
  margin: auto;
}

.wallet-token {
  .token-header {
    position: inherit;
    overflow: hidden;
  }

  .token-body,
  .token-detail-body {
    position: relative;

    .token-card {
      .card-body {

        // height: 80px;
        .col-12 {
          margin: 0 !important;
          font-style: normal;
          color: #FFFFFF;
          text-transform: capitalize;
          font-weight: 400;

          .token-image {
            margin-right: 17px;

            svg {
              width: 50px !important;
              height: 50px !important;
              object-fit: cover;
              display: inline-block;
            }
          }

          .token-name {
            font-family: 'Kardust' !important;
            font-size: 20px;
          }

          .token-type {
            font-family: 'Kardust' !important;
            font-weight: 600;
            font-size: 16px;
            color: #BF96FF;
          }

          .token-date {
            font-family: 'Kardust Condensed' !important;
            font-weight: 500;
            font-size: 12px;
          }

          .token-price {
            font-family: 'Kardust' !important;
            font-weight: 500;
            font-size: 18px;

            .token-unit {
              font-family: 'Kardust' !important;
              font-size: 12px;
            }
          }

          .token-show-dropdown {
            transition: all 0.3s ease-in;
            overflow: hidden;
            height: 0;
          }

          .token-show-detail-dropdown {
            height: 90px;
          }
        }

        .token-trash {
          position: absolute;
          right: 0;
          top: 0px;
          background: #F87979;
          border-radius: 0px 10px 0px 5px;
        }
      }
    }
  }

  @include media-breakpoint-down("sm") {
    .token-body {
      overflow: auto;
      // height: calc(100vh - 240px);
    }

    .token-detail-body {
      overflow: auto;
      // height: calc(100vh - 460px);
    }
  }
}

ul.filter {
  list-style: none;
  padding-left: 2px;
}

.filter {

  ul {
    list-style: none;
    padding-left: 2px;
  }

  .group {
    ul {
      padding-left: 15px;
    }
  }

  .title {
    // @include text-gradient($gradient-color);
    color: $badge-bg-color;
    @extend .f-monodb;
  }

  .filter-accordion-body {
    padding-left: 20px;
    margin-top: 16px;
    padding-left: 38px;

    ul {
      &.nav-pills {

        li.nav-item {
          margin-right: 15px;

          a.nav-link {
            color: $bg-li-color !important;
            padding: 0 15px !important;
            text-indent: 0;
            border: none;

            &.active {
              background-color: #fff !important;

              a.nav-link {
                color: $color !important;
              }
            }
          }

        }
      }
    }

    &.minmaxrow {
      input {
        width: 80px;

        &.datepicker {
          width: 120px;
        }

        height: 33px !important;
        font-size: 13px !important;
        text-align: center;
        padding-top: 3px;
      }
    }
  }
}

.filter,
.subNav {
  .vertical-divider {
    width: 30px;
    height: 0;
    border-width: 0.5px;
    transform: rotate(90deg);
  }

  .scroll-container {
    padding-left: 10px;
  }

  .category-filter {
    cursor: pointer;
    height: 30px;
    border-radius: 7px;
    overflow: hidden;

    &.active {
      // background: $gradient-color;
      background: linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%);
      // border: 1px solid #AB79F8;

      span {
        color: white;
      }
    }

    span {
      padding: 0 10px;
      font-size: 15px;
      font-weight: 400;
      white-space: pre;
    }


    &:not(.active) {
      // @include border-box($purple, 1px, 7px, 1);
      border: 1px solid $purple;
      padding: unset;

      span {
        // @include text-gradient($purple);
        color: #BF96FF;
      }
    }
  }
}

.accor-btn {

  &:not(.collapsed),
  &:hover {
    div {
      // @include text-gradient($gradient-color);
      color: $badge-bg-color;

      svg {

        path,
        rect {
          // fill: url(#gradient_paint);
          fill: $badge-bg-color;
        }
      }
    }
  }
}

.filter-input-check {
  input[type=checkbox] {

    &:not(:checked[type=checkbox]) {
      background-color: transparent;
      background-image: url(../../assets/icons/checkbox.svg);
      background-repeat: no-repeat;
      border: none;
      width: 16px;
      height: 16px;
    }

    &:checked[type=checkbox] {
      background-image: url(../../assets/icons/checkbox-onclick.svg);
      background-color: transparent;
      border: none;
    }
  }
}

.form-check-input.radio {
  &:not(:checked[type=radio]) {
    background-color: transparent;
    // background-image: url(../../assets/icons/radio.svg);
    border: 2px solid #bf96ff;
    width: 24px;
    height: 24px;
  }

  &:checked[type=radio] {
    background-image: url(../../assets/icons/radio-onclick.svg);
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
  }
}

.game-detail-header {
  line-height: 30px;
  margin: 15px 0px 15px 15px;
}

.back-arrow {
  position: absolute;
  top: 28px;
  left: 20px;
  z-index: 1;
  background-color: #000;
}

.game-detail-arrow {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-image: url(../../assets/icons/back-icon.svg);
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
}

.width-content {
  @include media-breakpoint-up('xl') {
    width: min-content
  }

  @include media-breakpoint-down('xl') {
    width: 100%;
  }
}

.br-10px {
  border-radius: 10px !important;
}

.ff-Kardust {
  font-family: Kardust !important;
}

.ff-Kardust-Expanded {
  font-family: 'Kardust Expanded' !important;
}

.ff-inherit {
  font-family: inherit !important;
}

.font-inherit {
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.mr-minus-10 {
  margin-right: -12px !important;
}

.owner-icon {
  width: 38px;
  height: 38px;
  @include border-box($gradient-color, 1.5px, 50px, 1);
  @include flex-center;

  svg {
    width: 13px !important;
  }
}


.token-item {
  .card-body {
    .token-name {
      font-weight: bold;
      white-space: normal;
      font-size: 14px;
    }

    .prices {
      >div>div {
        height: 22px;
      }

      .field {
        font-weight: 600;
        font-size: 12px;
        line-height: 15.36px;
        color: $purple;
      }
    }

  }
}


.react-datepicker-wrapper {
  width: auto;
}

@include media-breakpoint-up("md") {
  .menulist {
    .badge-custom {
      margin-left: 20px
    }
  }

}

@include media-breakpoint-down("lg") {
  .menulist {
    font-size: 15px !important;
    ;

    >div>a>div {
      position: relative;
    }

    >div>a>div>div {
      margin: 0 auto;
    }

    .badge-custom {
      top: 0;
      position: absolute;
      right: 16%;
    }

  }
}

.new-games-card {
  .custom-hide-scroll {
    .new-games-header {
      &:nth-of-type(1) {
        margin-left: 16px;
      }
    }
  }

  .new-games-header {
    .new-games-header-card {
      display: flex;
      width: 280px;
      height: 165px;
      margin-right: 20px;
      position: relative;
      background: linear-gradient(180deg, rgb(255 255 255) 0%, rgb(0 0 0) 71%, rgb(0 0 0) 86%);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 20px;

      .new-games-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .new-games-body {
        display: flex;
        position: absolute;
        width: 280px;
        justify-content: space-between;
        bottom: 20px;
        padding: 0 10px;
        font-style: normal;

        .new-games-maker {
          margin-right: 5px;
        }

        .new-games-header-card-title {
          font-family: 'Kardust Condensed';
          font-weight: 500;
          font-size: 13px;
          text-transform: capitalize;
          color: #FFFFFF;
          max-width: 160px;
        }

        .new-games-header-card-text {
          font-family: 'Kardust Condensed';
          font-weight: 500;
          font-size: 12px;
          color: #C4C4C4;
          max-width: 160px;
        }

        .new-games-header-card-btn {
          width: 52px;
          height: 23px;
          min-width: unset !important;
          font-family: 'Kardust' !important;
          font-weight: 400;
          font-size: 10px !important;
          padding-top: 4px;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .new-games-title {
    display: flex;
    justify-content: space-between;
    font-family: "Kardust";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    text-transform: uppercase;
    background: #BF96FF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .new-games-grid {
    // display: grid;
    // gap: 10px;
    // grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-height: 270px;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    .new-games-new {
      max-width: 341px !important;

      &.max-w-unset {
        max-width: unset !important;
      }

      .new-games-new-card {
        position: relative;
        display: flex;
        align-items: center;
        width: 325px;
        height: 80px;
        background: #32304E;
        border-radius: 10px;
        padding: 0 15px;
        justify-content: space-between;

        .new-games-new-card-title {
          font-family: 'Kardust';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 98.03%;
          text-transform: capitalize;
          background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .new-games-new-card-name {
          font-family: 'Kardust';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
          color: #FFFFFF;
        }

        .new-games-new-card-text {
          @include font-custom('Kardust Condensed', 13px, 500, normal);
          line-height: 98.03%;
          color: #C4C4C4;
        }

        .new-games-new-card-btn {
          width: 84px;
          height: 30px;
          min-width: unset !important;
          border-radius: 5px !important;
          font-family: "Kardust" !important;
          font-size: 15px !important;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          padding-bottom: 0 !important;
          justify-content: center;
        }

        .new-games-new-card-purchase {
          font-family: "Kardust Condensed";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 98.03%;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          color: #FFFFFF;
          justify-content: center;
        }

        .new-games-number {
          position: absolute;
          width: 28px;
          height: 25px;
          left: 0px;
          top: 0px;
          background: #F8A779;
          border-radius: 10px 0px;

          span {
            font-family: "Kardust";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            display: flex;
            height: 100%;
            text-transform: capitalize;
            background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.custom-hide-scroll {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.primary-color {
  color: #BF96FF !important;
}

.grey-color {
  color: #82808C;
}

.green-color {
  color: #22874A;
}

.first-letter::first-letter {
  margin-left: 3rem;
}

.border-box {
  @include media-breakpoint-down('sm') {
    margin: auto !important;
  }

  @include media-breakpoint-down('xsm') {
    width: 100% !important;
    margin: auto !important;
  }
}

.wallet-dash-card {
  @include media-breakpoint-down('sm') {
    justify-content: space-between;
    width: 330px;
    margin: auto !important;

    .card-mr-3 {
      margin-right: 0 !important;
    }
  }
}

.list-item-img {
  width: 60px;
  height: 60px !important;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

.p-relative {
  @include media-breakpoint-down('ll') {
    position: relative;
  }
}

.p-absolute {
  .title {
    font-size: 25px;
  }

  div {
    font-size: 16px;
  }

  @include media-breakpoint-down('ll') {
    position: absolute;

    .title {
      font-size: 18px;
    }

    div {
      font-size: 12px;
    }
  }
}

.levias-wrapper {
  * {
    font-family: 'Kardust Condensed' !important;
  }

  .levias-title {
    font-family: 'Kardust Condensed' !important;
  }
}

.gradient-text {
  background: $title-gradient-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


// filter icon
.form-check {

  .filter-icon-div {
    margin-left: -10px;

    @include media-breakpoint-down('sm') {
      margin: 0 5px 0 0;
    }
  }

  .filter-icon-div.active {
    svg {

      path,
      rect {
        fill: $badge-bg-color;
      }
    }
  }

  svg.icon-size {
    width: 19px !important;
    height: 19px !important;
  }
}


.w-390px {
  width: 390px !important;
}

.w-300 {
  width: 300px !important;
}

.h-200px {
  height: 200px !important;
}

.gallery-icon {
  font-size: 20px !important;

  &::after {
    content: "";
    background-image: url(../../assets/icons/gallery.svg);
    width: 26px;
    height: 22px;
    margin-right: 13px;
  }
}

.take-photo-icon {
  font-size: 20px !important;

  &::after {
    content: "";
    background-image: url(../../assets/icons/take-photo.svg);
    width: 24px;
    height: 21px;
    margin-right: 13px;
  }
}

.levias-gallery-icon {
  font-size: 20px !important;

  &::before {
    content: "";
    background-image: url(../../assets/icons/levias-gallery.svg);
    width: 26px;
    height: 22px;
    margin-right: 13px;
  }
}

.levias-take-photo-icon {
  font-size: 20px !important;

  &::before {
    content: "";
    background-image: url(../../assets/icons/levias-take-photo.svg);
    width: 24px;
    height: 21px;
    margin-right: 13px;
  }
}

.df-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.news {
  font-family: 'Noto Sans JP';
  font-size: 18px;
  line-height: 1.8;
}

.overlay-content {
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 100%;
}

.item-margin-buttom-without-maker {
  margin-bottom: 2.4rem !important;
}

.tx-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tx-ellipsis2 {
  white-space: normal !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 120%;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal !important;
}

.r-15px {
  right: 15px !important;
}

.l-15px {
  left: 15px !important;
}

.fc-pl-0 {
  ul {
    li {
      &:first-child {
        @include media-breakpoint-up('sm') {
          padding-left: 0px !important;
        }
      }
    }
  }
}

.lc-pr-15 {
  ul {
    li {
      &:last-child {
        padding-right: 0px !important;

        @include media-breakpoint-down('sm') {
          padding-right: 15px !important;
        }
      }
    }
  }
}

.swal2-custom-close {
  display: flex;
  margin-top: -66px !important;
  width: 100px !important;
  color: transparent !important;
  height: 50px !important;
  font-family: Kardust !important;
  font-weight: normal;
  font-size: 20px !important;
  background: transparent !important;

  &:after {
    background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%) !important;
    -webkit-background-clip: text !important;
    content: "close";
  }
}

.swal2-custom-close-jp {
  display: flex;
  margin-top: -66px !important;
  width: 110px !important;
  color: transparent !important;
  height: 50px !important;
  font-family: Kardust !important;
  font-weight: normal;
  font-size: 20px !important;
  background: transparent !important;
  justify-content: end !important;

  &:after {
    background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%) !important;
    -webkit-background-clip: text !important;
    content: "閉じる";
    //font-size:22px;

  }
}

.min-width-165px {
  min-width: 165px !important;
}

.min-width-180px {
  min-width: 180px !important;
}

.min-width-210px {
  min-width: 210px !important;
}

.max-width-235px {
  max-width: 235px !important;
}

.max-width-180px {
  max-width: 180px !important;
}

// #main-content, 
.max-width-main-content {
  max-width: 1043px !important;
}


.sticky-top {

  //background: #432055 !important;
}

#toolbarWrap {
  // transform: translate3d(0, 0, 0);
}

#toolbar {
  position: sticky;
  top: 0px;
  z-index: 1021;

  &.toolbarAnimation {
    position: fixed !important;
    background: $gradient-background;
    background-size: 100vw 100vh;
    width: 100%;
  }

  &.toolbarAnimation.initial {
    top: -75px !important;
  }

  &.toolbarAnimation.animate {
    top: 0px !important;
    transition: top 0.2s ease-in-out;
  }
}

.layout {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;

  &.hasInput {
    @include media-breakpoint-down('sm') {
      #toolbarWrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1020;
      }

      #toolbar {
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 1021;
      }

      .mainbody,
      #mypage #wrapper {
        position: fixed;
        top: $navHeight;
        width: 100%;
        // max-height: 600px;

        // @media screen and (min-height: 668px) {
        //   height: calc(100vh - 200px) !important;
        // }
      }

      &#levias {

        .mainbody,
        #mypage #wrapper {
          // bottom: -20px;
          padding-top: 20px;
          // height: calc(100vh - $navHeight) !important;
          // -webkit-overflow-scrolling: touch;
        }

      }
    }
  }
}

.news-list {
  .card {
    background-color: transparent !important;
    // max-width: 200px !important;
  }

  .card-image img {
    min-height: 115px !important;
    border-radius: 10px !important;
  }

  .card-image img.no-border-radius {
    border-radius: 0px !important;
  }

  .card-body {
    padding: 10px 0px !important;
  }
}

.min-w-auto {
  min-width: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.max-w-unset {
  max-width: unset !important;
}

.round-bracket {
  counter-reset: item;

  li {
    display: block;
    position: relative;
  }

  li:before {
    content: "(" counters(item, ".")")";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 5px;
  }
}

.mobile-body-line-break {
  white-space: normal !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px !important;
}

// react-multi-carousel (from ./item.js/ItemCarousel component)
.react-multi-carousel-track {
  // list-style: none;
  // padding: 0;
  // margin: 0;
  // display: flex;
  // flex-direction: row;
  // position: relative;
  // transform-style: preserve-3d;
  // -webkit-backface-visibility: hidden;
  // backface-visibility: hidden;
  // will-change: transform, transition;
  overflow-y: scroll !important;

  .item-fix-size {
    max-height: unset !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.ml-15px {
  margin-left: 15px !important;
}

.mr-15px {
  margin-right: 15px !important;
}

.width-180px {
  width: 180px !important;
  min-width: 180px !important;
}

.child-smoothable-transition {
  * {
    transition: all .5s !important;
    user-select: none !important;
  }

  @include media-breakpoint-down('lg') {

    .new-games-new,
    .new-games-header {

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }
}

.filter {

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    text-indent: 0;
  }
}

.ml-15px {
  margin-left: 15px !important;
}

.width-180px {
  width: 180px !important;
}

// w-***px => width: ***px !important;
.w {
  @for $i from 165 through 235 {
    @if ($i % 5==0) {
      &-#{$i}px {
        width: #{$i}px !important;
      }
    }
  }
}

.iframe-video {
  max-width: 100%;

  @include media-breakpoint-down('md') {
    // width: calc(100% + 44px) !important;
    // max-width: 110%;
    // margin-left: -22px;
    object-fit: cover;
    height: 230px;
  }
}

// cookie policy box
.cookiebanner {
  position: fixed !important;
  left: 0;
  width: calc(100% + 4px);
  text-align: center;
  border: none;
  border-radius: 15px 15px 0px 0px !important;
  padding: 30px;
  padding-bottom: 34px;
  margin: -2px;

  &:not(.levias_cookiebanner) {
    background: $gradient-background;
    color: #fff;
    bottom: -4px;
    border: 1px solid #BF96FF;
  }

  &.levias_cookiebanner {
    background: #FFF;
    color: $levias-color;
    bottom: -2px;
    box-shadow: -2px -5px 20px -12px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -2px -5px 20px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px -5px 20px -12px rgba(0, 0, 0, 0.75);
    @include border-box(transparent);

    .levias-btn {
      height: 40px !important;
    }
  }

  .btn-container {
    @include flex-center;

    @include media-breakpoint-down('xsm2') {
      justify-content: space-between !important;
    }

    @include media-breakpoint-up('xsm2') {
      .cookie-reject-btn {
        margin-right: 16px;
      }
    }
  }
}

.cookie-reject-btn {
  mix-blend-mode: normal;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;
  background-clip: text;
  font-family: 'Monoglyceride';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;

  &:not(.levias-btn) {
    border: 1px solid #bf96ff;
    color: #bf96ff;
  }

  padding: 10px 30px !important;
  padding-top: 4px !important;
  height: 40px;
  min-width: 223px;

  &::before {
    border: none;
    border-radius: 7px;
    box-sizing: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #000;
    background: $gradient-color;
  }

  @include media-breakpoint-down('md') {
    font-size: 16px !important;
  }

  @include media-breakpoint-down('xsm') {
    min-width: auto;
    font-size: 10px !important;
  }
}

.cookie-accept-btn {
  background: linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%);
  mix-blend-mode: normal;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;
  font-family: 'Monoglyceride' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  min-width: 95px;
  height: 40px;
  color: #fff;

  @include media-breakpoint-down('md') {
    font-size: 16px !important;
  }

  @include media-breakpoint-down('xsm') {
    min-width: auto;
    font-size: 10px !important;
  }
}

.cookies-policy-link {
  background: $gradient-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 425px) {

  .cookiebanner {
    padding: 20px 23px 30px !important;
    text-align: left;
  }

  .cookiebanner h2 {
    font-size: 25px;
  }

  .cookiebanner p {
    font-size: 14px;
  }

  .cookie-accept-btn,
  .cookie-reject-btn {
    font-size: 20px;
    margin-top: 5px;
    padding: 5px 20px !important;
  }

}

#cookie-policy {
  font-size: 16px !important;
  word-wrap: break-word;

  p {
    font-size: 16px;
  }

  ul {
    li {
      text-align: left;
      font-size: 16px;
      line-height: 1.4em;
    }
  }
}

.f-18 {
  font-size: 18px !important;
}

.dropdownicon {
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;

  &.open {
    transform: rotate(90deg);
  }
}

.text-i-50 {
  text-indent: 50px;
}

.menu-container {
  height: calc(100vh - 180px); // fallback for dvh
  height: calc(100dvh - 180px);
}

.currency-symbol-container {
  width: 27px;
  height: 27px;
  border: 1px solid $badge-bg-color;
  border-radius: 150px;
  @include flex-center;

  svg {
    height: 18px;
    width: 18px;
    @extend .active-3;
  }
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.en_lang {
  .btn-subscribe {

    .ladda-spinner {
      left: 39.5px !important;
    }
  }

  .btn-unsubscribe {

    .ladda-spinner {
      left: 27px !important;
    }
  }
}

.drawchain {
  .history {
    .card-body {
      padding: 9px 18px;

      .label {
        @extend .f-condensed;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: $purple;
        white-space: pre;
        margin-right: 20px;
      }

      .desc {
        @extend .f-condensed;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: right;
        color: #FFF;
        @extend .text-overflow;
      }

      .success {
        color: #00FF19;
      }

      .pending {
        color: #F87979;
      }
    }
  }
}

/*region JPLanguage*/
.jp_lang {
  .jp-fw-400 {
    font-weight: 400 !important;
  }

  .jp-f-notosan {
    font-family: 'Noto Sans JP', 'Noto Sans', sans-serif !important;
  }

  .jp-fs {
    @include generate-font-size();

    @each $size,
    $value in $resbreakpoints {
      &-#{$size} {
        @include fs-res($size);
      }
    }
  }

  #toolbar {
    .anikana-label {
      font-size: 20px !important;
    }
  }

  .btn {

    &-mini,
    &-mini:hover {
      font-size: 20px !important;

      span {
        font-size: 20px !important;
      }
    }

    &-custom {

      &-2,
      &-2:hover,
      &-3,
      &-3:hover,
      &-4,
      &-4:hover,
      &-5,
      &-5:hover,
      &-6,
      &-6:hover,
      &-cancel,
      &-cancel:hover,
      &-success,
      &-success:hover,
      &-active,
      &-active:hover,
      &-nonactive,
      &-nonactive:hover,
      &-filter,
      &-filter:hover {
        font-size: 20px !important;

        span {
          font-size: 20px !important;
        }
      }

      &-cancel,
      &-cancel:hover,
      &-active,
      &-active:hover {
        @include btn-center(0px);
      }
    }
  }

  .cookie-reject-btn {
    &:not(.levias-btn) {
      padding-top: 6px !important;
    }

    @include media-breakpoint-down('md') {
      font-size: 16px !important;
    }

    @include media-breakpoint-up('md') {
      font-size: 20px !important;
      min-width: 283px !important;
    }
  }

  p {
    font-size: 16px;
  }

  .cookie-reject-btn {
    &:not(.levias-btn) {
      padding-top: 6px !important;
    }

    @include media-breakpoint-down('md') {
      font-size: 16px !important;
    }

    @include media-breakpoint-up('md') {
      font-size: 20px !important;
      min-width: 283px !important;
    }
  }

  p {
    font-size: 16px;
  }

  .badge-custom {
    font-size: 12px !important;

    &-3 {
      font-size: 13px !important;
    }
  }
}

/*#endregion */

// *** Need to be placed last ***
/*#region ResponsiveGenerator*/
.col-res {
  // @media screen and (min-width: 992px) and (max-width: 1150px) {
  //     &.item-col {
  //         width: 50% !important;
  //     }
  // }

  // @media screen and (min-width: 1400px) and (max-width: 1450px) {
  //     &.item-col {
  //         width: 33.33333% !important;
  //     }
  // }

  @each $size,
  $value in $resbreakpoints {
    &-#{$size} {

      @each $width,
      $percent in $customCol {
        &-#{$width} {
          @include col-res($size, $percent)
        }
      }
    }
  }
}

.fs {
  @include generate-font-size();

  @each $size,
  $value in $resbreakpoints {
    &-#{$size} {
      @include fs-res($size);
    }
  }
}

.gx {
  @for $i from 0 through 50 {
    &-#{$i}px {
      --bs-gutter-x: #{$i}px !important;
    }
  }
}

.p {

  @each $position,
  $value in $positions {
    &-#{$position} {
      $i: 0;

      @while $i <=$spacing_limit {
        &-#{$i} {
          @include assign-spacing('padding', $position, $i);
          $i: $i +1;
        }
      }

      @each $i in $spacing_includes {
        &-#{$i} {
          @include assign-spacing('padding', $position, $i);
        }
      }
    }
  }

  @each $position,
  $value in $positions {

    &-#{$position} {

      @each $size,
      $spacingbreakpoint in $spacingbreakpoints {
        &-#{$size} {
          @include media-breakpoint-up($size) {
            $i: 0;

            @while $i <=$spacing_limit {
              &-#{$i} {
                @include assign-spacing('padding', $position, $i);
                $i: $i +1;
              }
            }

            @each $i in $spacing_includes {
              &-#{$i} {
                @include assign-spacing('padding', $position, $i);
              }
            }
          }
        }
      }
    }
  }
}

.m {

  @each $position,
  $value in $positions {
    &-#{$position} {
      $i: -$spacing_limit;

      @while $i <=$spacing_limit {
        &-#{$i} {
          @include assign-spacing('margin', $position, $i);
          $i: $i +1;
        }
      }

      @each $i in $spacing_includes {
        &-#{$i} {
          @include assign-spacing('margin', $position, $i);
        }
      }

      @each $i in $spacing_includes {
        &--#{$i} {
          @include assign-spacing('margin', $position, -$i);
        }
      }
    }
  }

  @each $position,
  $value in $positions {

    &-#{$position} {

      @each $size,
      $spacingbreakpoint in $spacingbreakpoints {
        &-#{$size} {
          @include media-breakpoint-up($size) {
            $i: -$spacing_limit;

            @while $i <=$spacing_limit {
              &-#{$i} {
                @include assign-spacing('margin', $position, $i);
                $i: $i +1;
              }
            }

            @each $i in $spacing_includes {
              &-#{$i} {
                @include assign-spacing('margin', $position, $i);
              }
            }

            @each $i in $spacing_includes {
              &--#{$i} {
                @include assign-spacing('margin', $position, -$i);
              }
            }
          }
        }
      }
    }
  }
}

/*#endregion */

.square-image {
  height: 90px !important;
  width: 90px !important;
  object-fit: cover;
  object-position: center;
}

.fs-inherit {
  font-family: inherit !important;
}

.btn-custom-active-sm {
  color: $color !important;
  border: none !important;
  min-width: 100px;
  border-radius: 7px;
  background: linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%);
  display: grid;
  place-items: center;
  width: auto;
  outline: transparent !important;

  &:focus {
    outline: transparent !important;
  }
}

.btn-custom-active-sm:disabled {
  color: white !important;
  background: #82808C;
  backdrop-filter: blur(10px);
  opacity: .65;
}

.btn-custom-cancel-sm,
.btn-custom-cancel-sm:focus,
.btn-custom-cancel-sm:hover .btn-custom-cancel-sm:disabled {
  border-width: 1px;
  border-color: $purple !important;
  border-style: solid !important;
  border-radius: 7px;
  width: auto;
  color: $purple !important;
  box-sizing: border-box;
  display: flex;
  place-items: center;
  outline: transparent !important;
}

.btn-custom-cancel-sm:disabled,
.semi-disabled,
.semi-disabled:focus,
.semi-disabled:hover {
  border-color: transparent !important;
  color: white !important;
  background-color: #82808C;
  backdrop-filter: blur(10px);
  opacity: .65;
}

.seperator {
  width: 2px;
  background: linear-gradient(to bottom, #101c0a 0%, #E85EFF 47%, #10211f 100%);
}

.bg-gradient-transparent {
  background: linear-gradient(90deg, rgba(26, 23, 46, 1) 0%, rgba(26, 23, 46, 0.8337928921568627) 58%);
}

.bd-gradient-rounded-pill {
  border: 1px solid $purple;
}

.start-n10 {
  left: -10px;
}

.opacity-40 {
  filter: opacity(.4);
}

.square-filter-btn,
.square-filter-btn:hover {
  @include text-gradient;
  width: inherit !important;
  color: white;
  border: none;
  outline: transparent;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to bottom, #101c0a 0%, #E85EFF 47%, #10211f 100%);
  }
}

.isolate {
  isolation: isolate;
}

.min-w-110 {
  min-width: 110px;
}

.min-h-220px {
  min-height: 220px;
}

.light-gradient {
  background-image: linear-gradient(0.46deg, rgba(0, 0, 0, 0.85) 0.43%, rgba(0, 0, 0, 0.57) 29.48%, rgba(0, 0, 0, 0) 51.58%, rgba(0, 0, 0, 0) 99.64%);
}

.draw-chain-btn {
  z-index: 3;
  border: 1px solid #BF96FF;
  outline: transparent;
  border-radius: 5px;
  display: grid;
  place-items: center;
  color: $purple;
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
}

.px-25px {
  padding-left: 25px;
  padding-right: 25px;
}

.gap-7px {
  gap: 7px;
}

.bulk-wrapper-div {
  background-color: #bf96ff;
  height: 268px;
  width: 90%;
  margin: auto;
  padding: 0px 10px;

  .position-absolute.type {
    z-index: 2;
    left: 0;
    top: 62%;
  }

  .content-div {
    background: url('../../assets/icons/bulk-bg.svg') no-repeat;
    background-size: cover;
    height: 309px;
    width: 87%;
    bottom: 0;

    .content-img {
      height: 160px;
      width: 100px;
      border-radius: 10px;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
      // background: url('../../assets/images/item_img.png'), #D9D9D9;

      &:nth-of-type(1) {
        transform: rotate(-9.68deg);
        left: -10%;
      }

      &:nth-of-type(2) {
        transform: rotate(-4.63deg);
        left: 10%;
      }

      &:nth-of-type(3) {
        transform: rotate(6.04deg);
        left: 30%;
      }
    }
  }
}

.clipboard {
  border: 1px solid $purple;
  border-radius: 15px;
  padding: 16px 17px 23px 17px;
  color: white;

  &>div {
    word-break: break-all;
  }
}

.show-key-modal {
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

@media screen and (max-width:991px) {
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

// .ladda-button:hover {
//   background-color: #999 !important;
// }

.key-icon {
  width: 36px;
  height: 28px;

  &.large {
    width: 82px;
    height: 63px;
  }
}

.phone-stack-icon-lg {
  width: 80px;
  height: 92px;
}

.swipeable-list-item__content {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: block !important;
  position: relative;
  z-index: 1;
}

.swipeable-list-item__trailing-actions {
  display: flex;
  width: 0px;
  position: absolute;
  right: 10px;
  top: 0;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  // z-index: -1;
}

.delete-token {
  background-color: #BF96FF;
  border-radius: 10px;
  height: 100px;
  padding-top: 30px;
  padding-left: 35px;
  margin-left: -3px;
}

.delete-icon {
  width: 35px;
  height: 35px;
}


.text-shadow {
  text-shadow: rgb(0, 0, 0) 2px 2px 5px;
}

.space-10px {
  gap: 10px;
  padding: 10px;
}

.space-15px {
  gap: 15px;
  padding: 15px;
}

.responsive-grid {
  display: grid;

  @media screen and (max-width:575px) {
    grid-template: auto / repeat(2, minmax(0, 210px));
  }

  @media screen and (min-width:576px) and (max-width:767px) {
    grid-template: auto / repeat(auto-fit, minmax(0, 210px));
  }

  @media screen and (min-width:768px) {
    grid-template: auto / repeat(auto-fit, minmax(0, 210px));
  }
}

.react-bootstrap-modal-custom {
  @media screen and (max-width:575px) {
    min-width: unset !important;
    width: 95%;
  }
}

button {
  & .drawchain-icon-bg {
    fill: #BF96FF;
  }

  & .drawchain-icon-chain {
    fill: #19162E;
  }
}

button:disabled,
button:is(.semi-disabled) {
  & .drawchain-icon-bg {
    fill: white;
  }

  & .drawchain-icon-chain {
    fill: gray;
  }
}

.bordor-radius-15 {
  border-radius: 15px !important;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.text-fancy-gradient {
  background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.primary-bg-gradient {
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100vw 100vh;
}

.header-animation {
  animation: slide-in-from-top 150ms forwards;
}

@keyframes slide-in-from-top {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.height-without-nav {
  height: $heightWithoutNav;
}

.text-align-bt {
  display: inline-block;
  padding-bottom: 5px;
}

div:has(.tab-dropdown-icon:checked)+.tab-dropdown-list {
  display: block !important;
}

.tab-dropdown-list {
  display: none !important;
}

.active-dropdown-item {
  color: white !important;
  background-color: #54428E !important;
}

.contact-div {
  // width: 100%;

  @include media-breakpoint-up('lg') {
    width: 60%;
  }

  @include media-breakpoint-up('xl') {
    width: 50%;
  }
}

.mt-minus-jp-55 {
  margin-top: -55px !important;
}

.reset-spacing {
  & p {
    margin: 0;
  }

  & * {
    line-height: 2;
  }
}

.f-Inter {
  font-family: Inter, system-ui !important;
}

.border-radius-10px {
  border-radius: 10px !important;
}

.gap-20 {
  gap: 20px;
}

.flex-1 {
  flex: 1;
}

.arcana-plus-icon-large{
  width:56px;
  height:56px;
}